import SettingsLeads from "components/administration/leads/Settings";
import Leads from "components/administration/leads/Leads";

export default Settings;

function Settings(props) {
  return (
    <>
      <SettingsLeads />
      <div className="mb-3"></div>
      <Leads />
      <div className="mb-3"></div>
    </>
  );
}
