import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Add, Edit } from './NewsFeed';
import Modal from '../../shared/Modal';
import ModalAlert from '../../shared/ModalAlert';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

export default NewsFeedTable;

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

function NewsFeedTable(props) {
    const [show, setShow] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [bodyPreview, setBodyPreview] = useState(null);
    const [constructor, setConstructor] = useState({});
    const table = useRef();
    const image = useRef();
    const selectManagers = useRef();

    const updateDataTable = async () => {
        $(table.current).DataTable().ajax.reload();
    };

    const publish = useCallback((id) => {
        fetchWrapper.post(`api/news/publish/${id}`).then((result) => {
            updateDataTable();
        });
    }, []);

    const preview = useCallback((id) => {
        fetchWrapper.get(`api/news/unpublisheds/${id}`).then((result) => {
            let urlParams = new URLSearchParams(result.linkVideo);
            let body = <>
                <h5>{result.title}</h5>
                <div dangerouslySetInnerHTML={{__html: result.description}}></div>
                {
                    result.image ?
                        loadImage(result.image) : null
                }
                {
                    result.linkVideo ?
                        urlParams.has('https://www.youtube.com/watch?v') ?
                            <iframe width='100%' className='p-2' src={'https://www.youtube.com/embed/' + urlParams.get('https://www.youtube.com/watch?v')} title='youtube-preview'></iframe>
                            : null
                        : null
                }
            </>
            setBodyPreview(body);
            setShowPreview(true);
        });
    }, []);

    const loadDataTable = useCallback(() => {
        if (!$.fn.DataTable.isDataTable(table.current)) {
            $(table.current).DataTable({
                ajax: function (data, callback, settings) {
                    fetchWrapper.get('api/news/unpublisheds').then((result) => {
                        callback({
                            data: result
                        });
                    });
                },
                dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
                language: {
                    "emptyTable": "Нет данных",
                    "info": "Показано _START_ до _END_ из _TOTAL_",
                    "infoEmpty": "Показано 0 до 0 из 0",
                    "infoFiltered": "(Выбрано из _MAX_)",
                    "zeroRecords": "Не найдено",
                    "loadingRecords": "Загрузка...",
                    "search": "Поиск:"
                },
                buttons: {
                    dom: {
                        button: {
                            tag: 'button',
                            className: ''
                        }
                    },
                    buttons: [
                        {
                            text: 'Добавить',
                            className: 'btn btn-success',
                            action: async function (e, dt, node, config) {
                                setConstructor(await Add(selectManagers));
                                setShow(true);
                            }
                        }
                    ]
                },
                responsive: true,
                paging: false,
                scrollY: 300,
                scrollX: true,
                order: [[1, 'asc']],
                columns: [
                    {
                        title: '',
                        data: null,
                        orderable: false,
                        width: 10,
                        render: function () {
                            return '';
                        }
                    },
                    {
                        title: 'Дата создания',
                        data: 'dateCreate',
                        width: 80,
                        render: function (t) {
                            return moment(t).format('DD.MM.YYYY HH:mm');
                        }
                    },
                    {
                        title: 'Название',
                        data: null,
                        render: function (t) {
                            if(t.title){
                                return t.title;
                            }
                            else{
                                return '';
                            }
                        }
                    },
                    {
                        title: 'Функции',
                        data: null,
                        orderable: false,
                        width: 10,
                        render: function (t) {
                            return `<span class="badge edit" style="cursor: pointer; width: 30px;" data-id="${t.id}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2369eb" class="bi bi-pencil" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" /></svg></span>
                                <span class="badge publish" style="cursor: pointer; width: 30px;" data-id="${t.id}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff6cc0" class="bi bi-arrow-up-square" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/></svg></span>
                                <span class="badge preview" style="cursor: pointer; width: 30px;" data-id="${t.id}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#56ff55" class="bi bi-caret-right-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M5.795 12.456A.5.5 0 0 1 5.5 12V4a.5.5 0 0 1 .832-.374l4.5 4a.5.5 0 0 1 0 .748l-4.5 4a.5.5 0 0 1-.537.082z"/></svg>`;
                        }
                    }
                ]
            });

            $(table.current).on('click', 'span.edit', function () {
                edit($(this).data().id);
            })
            $(table.current).on('click', 'span.publish', function () {
                publish($(this).data().id);
            })
            $(table.current).on('click', 'span.preview', function () {
                preview($(this).data().id);
            })
        }
    }, [publish, preview]);

    useEffect(() => {
        loadDataTable();
    }, [loadDataTable]);

    useEffect(() => {
        setTimeout(() => {
            $(table.current).DataTable().columns.adjust().draw();
        }, 400);
    });

    async function edit(id) {
        setConstructor(await Edit(id, selectManagers));
        setShow(true);
    }

    function loadImage(name) {
        let div = <div className='d-flex justify-content-center p-2' ref={image}></div>;
        if (name) {
            fetchWrapper.getFileShow(`api/news/images/${name}`).then((result) => {
                $(image.current).append("<img style='max-width: 400px; max-height:400px;' />");
                $(image.current).find('img').attr("src", result);
            });
        }

        return div;
    }

    return (
        <>
            <table className='w-100 display nowrap' ref={table}></table>
            {
                show === true ? <Modal
                    hide={() => setShow(false)}
                    title={constructor.title}
                    body={constructor.body}
                    getDataFunc={constructor.getDataFunc}
                    saveName={constructor.saveName}
                    saveFunc={constructor.saveFunc}
                    successFunc={() => {
                        setShow(false);
                        updateDataTable();
                    }} /> : ''
            }
            {
                showPreview ?
                    <ModalAlert
                        hide={() => setShowPreview(false)}
                        title={'Предпоказ новости'}
                        body={bodyPreview}
                    /> : ''
            }
        </>
    );
}
