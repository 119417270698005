import React, { useCallback, useEffect, useRef, useState } from "react";
import ModalAlert from "../../shared/ModalAlert";

import { fetchWrapper } from "../../../helpers/fetchwrapper";

export default ChecksTable;

const moment = require("moment");
const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function ChecksTable(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [links, setLinks] = useState(null);
  const table = useRef();

  const updateDataTable = async () => {
    $(table.current).DataTable().ajax.reload();
  };

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        ajax: function (data, callback, settings) {
          fetchWrapper.get(`api/checks/getNeedCheck`).then((result) => {
            callback({
              data: result,
            });
          });
        },
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[3, "desc"]],
        columns: [
          {
            title: "Менеджер",
            data: null,
            render: function (t) {
              return `${t.user.lastName} ${t.user.firstName}`;
            },
          },
          {
            title: "Номер",
            data: "number",
          },
          {
            title: "Сумма",
            data: "sum",
          },
          {
            title: "Дата и время",
            data: "datePay",
            type: "ru_date",
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Инициалы",
            data: null,
            render: function (t) {
              return `${t.lastName} ${t.firstName}`;
            },
          },
          {
            title: "Реквизиты",
            data: "requisites",
          },
          {
            title: "Комментарий",
            data: "comment",
          },
          {
            title: "Город",
            data: "city",
          },
          {
            title: "Ссылки",
            data: null,
            width: 10,
            className: "dt-center",
            render: function (t) {
              return (
                '<span class="badge links" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2369eb" class="bi bi-clipboard" viewBox="0 0 16 16">' +
                '<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>' +
                '<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>' +
                "</svg></span>"
              );
            },
          },
          {
            title: "Функции",
            data: null,
            render: function (t) {
              return `<span title="Проверено" class="badge checked" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#41b52a" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                    </svg></span>`;
            },
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    $(table.current)
      .off()
      .on("click", "span.links", function () {
        setLinks(
          $(table.current).DataTable().row($(this).parent().parent()).data()
            .links
        );
        setShowAlert(true);
      });

    $(table.current).on("click", "span.checked", function () {
      let choose = $(table.current)
        .DataTable()
        .row($(this).parent().parent())
        .data();
      if (choose) {
        let data = new FormData();
        data.append("id", choose.id);
        fetchWrapper.post(`api/checks/checked`, data, false).then(() => {
          updateDataTable();
        });
      }
    });

    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
      {showAlert ? (
        <ModalAlert
          hide={() => setShowAlert(false)}
          title={"Ссылки по чеку"}
          body={
            links.length > 0 ? (
              <div>
                {links.split("\n").map((e) => (
                  <div key={e} className="d-flex justify-content-between">
                    <span>{e.replace("<!>", "\n")}</span>
                    <span
                      className="badge"
                      onClick={() => {
                        navigator.clipboard.writeText(e.replace("<!>", "\n"));
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#2369eb"
                        className="bi bi-clipboard"
                        viewBox="0 0 24 24"
                      >
                        ' +
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        ' +
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                        ' +
                      </svg>
                    </span>
                  </div>
                ))}
                <button
                  className="btn btn-primary mt-2 w-100"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      links.replaceAll("\n", "\n\n").replaceAll("<!>", "\n")
                    );
                  }}
                >
                  Скопировать все ссылки
                </button>
              </div>
            ) : (
              <div>Ссылок не найдено</div>
            )
          }
        />
      ) : (
        ""
      )}
    </>
  );
}
