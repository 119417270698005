import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

import Widget from "components/shared/templates/Widget";
import CurrentsTable from "./CurrentsTable";

import "./styles/currents.css";

import { fetchWrapper } from "../../../helpers/fetchwrapper";
import { Requisites } from "helpers/requisites";

const moment = require("moment");
const $ = require("jquery");

export const Add = async () => {
  let requisites = Requisites;
  return {
    title: "Новая продажа",
    body: (
      <>
        <div className="form-group">
          <label htmlFor="number">Номер</label>
          <input
            type="text"
            className="form-control"
            id="number"
            placeholder="Введите номер чека"
          />
        </div>
        <div className="form-group">
          <label htmlFor="sum">Сумма</label>
          <input
            type="number"
            className="form-control"
            id="sum"
            placeholder="Введите сумму"
          />
        </div>
        <div className="form-group">
          <label htmlFor="time">Время</label>
          <input
            type="time"
            className="form-control"
            id="time"
            placeholder="Введите время"
          />
        </div>
        <div className="form-group">
          <label htmlFor="date">Дата</label>
          <input
            type="date"
            className="form-control"
            id="date"
            placeholder="Введите дату"
            defaultValue={moment().format("YYYY-MM-DD")}
          />
        </div>
        <div className="row">
          <label>Инициалы</label>
          <div className="form-group col-6">
            <input
              type="text"
              className="form-control"
              maxLength="1"
              id="lastName"
              placeholder="Введите фамилию"
            />
          </div>
          <div className="form-group col-6">
            <input
              type="text"
              className="form-control"
              maxLength="1"
              id="firstName"
              placeholder="Введите имя"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="requisites">Реквизиты</label>
          <Select
            id="requisites"
            options={requisites}
            isMulti={false}
            onChange={(e) => (requisites = e)}
            placeholder="Выберите реквизиты"
            maxMenuHeight={150}
          />
        </div>
        <div className="form-group">
          <label htmlFor="comment">Комментарий</label>
          <input
            type="text"
            className="form-control"
            id="comment"
            placeholder="Введите комментарий(необязательно)"
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">Город</label>
          <input
            type="text"
            className="form-control"
            id="city"
            placeholder="Введите город"
          />
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        number: body.find("#number").val(),
        sum: body.find("#sum").val() ? body.find("#sum").val() : null,
        datePay:
          body.find("#date").val() && body.find("#time").val()
            ? body.find("#date").val() + "T" + body.find("#time").val()
            : null,
        lastName: body.find("#lastName").val(),
        firstName: body.find("#firstName").val(),
        requisites: requisites.value,
        comment: body.find("#comment").val(),
        city: body.find("#city").val(),
      };

      return fetchWrapper.post("api/checks/add", data);
    },
  };
};

export default Currents;

function Currents(props) {
  const [loadForming, setLoadForming] = useState(false);
  const [results, setResults] = useState(null);
  const fromDate = useRef();

  const forming = () => {
    setResults(null);
    setLoadForming(true);
    let data = {
      date: $(fromDate.current).val(),
    };
    fetchWrapper.post(`api/leads/getbyday`, data).then((result) => {
      setResults(result);
      setLoadForming(false);
    });
  };

  useEffect(() => {
    forming();
  }, []);

  return (
    <>
      <Widget>
        <div className="row justify-content-between">
          <div className="d-sm-flex align-items-center col-sm-6">
            <div className="d-flex align-items-center">
              <h4>Лиды</h4>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end col-sm-6">
            <div className="d-flex align-items-center">
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="ms-auto ms-sm-4 mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={fromDate}
                onChange={forming}
              ></input>
            </div>
          </div>
        </div>
        <CurrentsTable results={results} updateDataTable={forming} />
      </Widget>
    </>
  );
}
