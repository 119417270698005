import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";

import Widget from "components/shared/templates/Widget";
import CurrentsTable from "./CurrentsTable";
import * as RiIcon from "react-icons/ri";

import "./styles/currents.css";

import { fetchWrapper } from "../../../helpers/fetchwrapper";
import { Requisites } from "helpers/requisites";

const moment = require("moment");

export const Add = async () => {
  let requisites = Requisites;
  return {
    title: "Новая продажа",
    body: (
      <>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="additionPayment"
          />
          <label className="form-check-label" htmlFor="additionPayment">
            Доплата
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="number">Номер</label>
          <input
            type="text"
            className="form-control"
            id="number"
            placeholder="Введите номер чека"
          />
        </div>
        <div className="form-group">
          <label htmlFor="sum">Сумма</label>
          <input
            type="number"
            className="form-control"
            id="sum"
            placeholder="Введите сумму"
          />
        </div>
        <div className="form-group">
          <label htmlFor="time">Время</label>
          <input
            type="time"
            className="form-control"
            id="time"
            placeholder="Введите время"
          />
        </div>
        <div className="form-group">
          <label htmlFor="date">Дата</label>
          <input
            type="date"
            className="form-control"
            id="date"
            placeholder="Введите дату"
            defaultValue={moment().format("YYYY-MM-DD")}
          />
        </div>
        <div className="row">
          <label>Инициалы</label>
          <div className="form-group col-6">
            <input
              type="text"
              className="form-control"
              maxLength="1"
              id="lastName"
              placeholder="Введите фамилию"
            />
          </div>
          <div className="form-group col-6">
            <input
              type="text"
              className="form-control"
              maxLength="1"
              id="firstName"
              placeholder="Введите имя"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="requisites">Реквизиты</label>
          <Select
            id="requisites"
            options={requisites}
            isMulti={false}
            onChange={(e) => (requisites = e)}
            placeholder="Выберите реквизиты"
            maxMenuHeight={150}
          />
        </div>
        <div className="form-group">
          <label htmlFor="comment">Комментарий</label>
          <input
            type="text"
            className="form-control"
            id="comment"
            placeholder="Введите комментарий(необязательно)"
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">Город</label>
          <input
            type="text"
            className="form-control"
            id="city"
            placeholder="Введите город"
          />
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        isAdditionalPayment: body.find("#additionPayment").prop("checked"),
        number: body.find("#number").val(),
        sum: body.find("#sum").val() ? body.find("#sum").val() : null,
        datePay:
          body.find("#date").val() && body.find("#time").val()
            ? body.find("#date").val() + "T" + body.find("#time").val()
            : null,
        lastName: body.find("#lastName").val(),
        firstName: body.find("#firstName").val(),
        requisites: requisites.value,
        comment: body.find("#comment").val(),
        city: body.find("#city").val(),
      };

      return fetchWrapper.post("api/checks/add", data);
    },
  };
};

export default Currents;

function Currents(props) {
  const [loadRatings, setLoadRatings] = useState(false);
  const [rating, setRating] = useState(0);
  const [ratingGroup, setRatingGroup] = useState(0);

  const getRatings = useCallback(() => {
    setLoadRatings(true);
    fetchWrapper.get("api/rating/getrating").then((rating) => {
      setRating(rating);

      fetchWrapper.get("api/rating/getratinggroup").then((ratingGroup) => {
        setRatingGroup(ratingGroup);

        setLoadRatings(false);
      });
    });
  }, []);

  const getStars = (count) => {
    if (count < 6) {
      return (
        <>
          <span className={count >= 1 ? "active" : ""}></span>
          <span className={count >= 2 ? "active" : ""}></span>
          <span className={count >= 3 ? "active" : ""}></span>
          <span className={count >= 4 ? "active" : ""}></span>
          <span className={count === 5 ? "active" : ""}></span>
        </>
      );
    } else {
      return <div>Гений продаж</div>;
    }
  };

  const getStarsGroup = (count) => {
    if (count < 6) {
      return (
        <>
          <span className={count >= 1 ? "active" : ""}></span>
          <span className={count >= 2 ? "active" : ""}></span>
          <span className={count >= 3 ? "active" : ""}></span>
          <span className={count >= 4 ? "active" : ""}></span>
          <span className={count === 5 ? "active" : ""}></span>
        </>
      );
    } else {
      return <div>Гений продаж</div>;
    }
  };

  useEffect(() => {
    getRatings();
  }, [getRatings]);

  return (
    <>
      <Widget>
        {loadRatings ? (
          <>
            <span className="spinner-border spinner-border-sm mb-2"></span>
          </>
        ) : (
          <>
            <div>
              <div className="d-flex justify-content-end align-items-center">
                <div className="rating-result">{getStars(rating)}</div>
                <RiIcon.RiStarSmileFill size={24} color={"#060b26"} />
              </div>
              <div className="d-flex justify-content-end align-items-center mb-2">
                <div className="rating-result">
                  {getStarsGroup(ratingGroup)}
                </div>
                <RiIcon.RiTeamFill size={24} color={"#060b26"} />
              </div>
            </div>
          </>
        )}
        <div>
          <CurrentsTable />
        </div>
      </Widget>
    </>
  );
}
