import React, { useState } from "react";

import Modal from "components/shared/Modal";
import Widget from "components/shared/templates/Widget";
import { AccountantTable } from "./AccountantTable";
import { ManagerTable } from "./ManagerTable";
import { ComparerTable } from "./ComparerTable";

import { fetchWrapper } from "helpers/fetchwrapper";

import "./styles/comparer.css";

const moment = require("moment");
const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

export const ChangeSum = (check) => {
  return {
    title: "Изменение суммы чека",
    body: (
      <>
        <input
          type="text"
          className="form-control"
          id="id"
          value={check.id}
          hidden={true}
        />
        <div className="form-group">
          <label htmlFor="sum">Сумма</label>
          <input
            type="number"
            className="form-control"
            id="sum"
            placeholder="Введите сумму"
            defaultValue={check.sum}
          />
        </div>
      </>
    ),
    saveName: "Сохранить",
    saveFunc: function (body) {
      let data = {
        id: body.find("#id").val(),
        sum: body.find("#sum").val() ? body.find("#sum").val() : 0,
      };

      return fetchWrapper.post("api/checks/changeSum", data);
    },
  };
};

export default Comparer;

function Comparer(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [constructorDialog, setConstructorDialog] = useState({});
  const accountantTable = React.createRef();
  const managerTable = React.createRef();
  const comparerTable = React.createRef();

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getData(date) {
    return fetchWrapper.get(`api/checks/fromDateUnresolved/${date}`);
  }

  const cancelCheck = () => {
    let managerRowData = $(managerTable.current)
      .DataTable()
      .row(".selected")
      .data();
    if (managerRowData) {
      let data = new FormData();
      data.append("id", managerRowData.id);
      fetchWrapper.post(`api/checks/cancel`, data, false).then(() => {
        $(managerTable.current)
          .DataTable()
          .row(".selected")
          .remove()
          .draw(false);
      });
    }
  };

  const manualComparer = () => {
    let managerRowData = $(managerTable.current)
      .DataTable()
      .row(".selected")
      .data();
    let accountantRow = $(accountantTable.current).find("tr.selected");
    if (managerRowData && accountantRow.length > 0) {
      $(managerTable.current).DataTable().row(".selected").remove().draw(false);
      $(accountantTable.current)
        .DataTable()
        .row(".selected")
        .remove()
        .draw(false);

      if ($(accountantTable.current).DataTable().rows().count() === 0) {
        $(accountantTable.current).DataTable().row.add([]).draw(false);
      }

      let item = {
        accountant: {
          time: $(accountantRow[0]).find('input[name="time"]').val(),
          requisites: $(accountantRow[0])
            .find('select[name="requisites"]')
            .val(),
          sum: $(accountantRow[0]).find('input[name="sum"]').val(),
          initials: $(accountantRow[0]).find('input[name="initials"]').val(),
        },
        id: managerRowData.id,
        datePay: managerRowData.datePay,
        number: managerRowData.number,
        sum: managerRowData.sum,
        requisites: managerRowData.requisites,
        lastName: managerRowData.lastName,
        firstName: managerRowData.firstName,
        comment: managerRowData.comment,
        city: managerRowData.city,
        user: managerRowData.user,
      };

      $(comparerTable.current).DataTable().row.add(item).draw();
    }
  };

  const autoComparer = () => {
    let accountantRows = $(accountantTable.current).find("tr");
    let managerRowsData = $(managerTable.current).DataTable().rows().data();
    for (let i = 0; i < accountantRows.length; i++) {
      for (let j = 0; j < managerRowsData.length; j++) {
        if (!managerRowsData[j].status) {
          let item = {
            accountant: {
              time: $(accountantRows[i]).find('input[name="time"]').val(),
              requisites: $(accountantRows[i])
                .find('select[name="requisites"]')
                .val(),
              sum: parseInt(
                $(accountantRows[i]).find('input[name="sum"]').val()
              ),
              initials: $(accountantRows[i])
                .find('input[name="initials"]')
                .val(),
            },
            id: managerRowsData[j].id,
            datePay: managerRowsData[j].datePay,
            number: managerRowsData[j].number,
            sum: managerRowsData[j].sum,
            requisites: managerRowsData[j].requisites,
            lastName: managerRowsData[j].lastName,
            firstName: managerRowsData[j].firstName,
            comment: managerRowsData[j].comment,
            city: managerRowsData[j].city,
            user: managerRowsData[j].user,
          };
          if (item.accountant.time) {
            if (
              Math.abs(
                (Date.parse("01/01/2000 " + item.accountant.time) -
                  Date.parse(
                    "01/01/2000 " + moment(item.datePay).format("HH:mm")
                  )) /
                  1000 /
                  60
              ) <= 7
            ) {
              if (item.requisites === item.accountant.requisites) {
                if (item.sum === item.accountant.sum) {
                  if (
                    item.lastName + " " + item.firstName ===
                    item.accountant.initials
                  ) {
                    $(managerTable.current)
                      .DataTable()
                      .rows(function (idx, data, node) {
                        return data.id === managerRowsData[j].id ? true : false;
                      })
                      .remove()
                      .draw(false);
                    $(accountantTable.current)
                      .DataTable()
                      .row(accountantRows[i])
                      .remove()
                      .draw(false);

                    managerRowsData[j].status = 1;

                    if (
                      $(accountantTable.current).DataTable().rows().count() ===
                      0
                    ) {
                      $(accountantTable.current)
                        .DataTable()
                        .row.add([])
                        .draw(false);
                    }

                    $(comparerTable.current).DataTable().row.add(item).draw();
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const acceptCheck = (choose) => {
    if (choose) {
      let data = new FormData();
      data.append("id", choose.id);
      fetchWrapper.post(`api/checks/accept`, data, false).then(() => {
        $(comparerTable.current).DataTable().row(choose).remove().draw(false);
      });
    }
  };

  const returnCheck = (choose) => {
    if (choose) {
      $(comparerTable.current)
        .DataTable()
        .rows(function (idx, data, node) {
          return data.id === choose.id ? true : false;
        })
        .remove()
        .draw(false);
      $(accountantTable.current)
        .DataTable()
        .row.add([
          choose.accountant.time,
          choose.accountant.requisites,
          choose.accountant.sum,
          choose.accountant.initials,
        ])
        .draw(false);
      let item = {
        id: choose.id,
        datePay: choose.datePay,
        number: choose.number,
        sum: choose.sum,
        requisites: choose.requisites,
        lastName: choose.lastName,
        firstName: choose.firstName,
        comment: choose.comment,
        city: choose.city,
        user: choose.user,
      };
      $(managerTable.current).DataTable().row.add(item).draw(false);
    }
  };

  return (
    <>
      <div className="row mb-3">
        <div className="accountant-widget">
          <Widget>
            <AccountantTable ref={accountantTable} />
          </Widget>
        </div>
        <div className="manager-widget">
          <Widget>
            <ManagerTable
              cancelCheck={cancelCheck}
              manualComparer={manualComparer}
              autoComparer={autoComparer}
              getData={getData}
              getRandomColor={getRandomColor}
              setConstructorDialog={setConstructorDialog}
              setShowDialog={setShowDialog}
              ref={managerTable}
            />
          </Widget>
        </div>
      </div>
      <Widget>
        <ComparerTable
          acceptCheck={acceptCheck}
          returnCheck={returnCheck}
          ref={comparerTable}
        />
      </Widget>
      {showDialog ? (
        <Modal
          hide={() => setShowDialog(false)}
          title={constructorDialog.title}
          body={constructorDialog.body}
          getDataFunc={constructorDialog.getDataFunc}
          saveName={constructorDialog.saveName}
          saveFunc={constructorDialog.saveFunc}
          successFunc={(response) => {
            let table = $(managerTable.current).DataTable();
            let val = $(table.table().container())
              .find("#dateManagerTable")
              .val();
            if (val) {
              getData(val).then((data) => {
                data.forEach(function (current, indexCurrent) {
                  data.forEach(function (compare, indexCompare) {
                    if (
                      current.number === compare.number &&
                      current.id !== compare.id
                    ) {
                      compare.color = current.color
                        ? current.color
                        : getRandomColor();
                    }
                  });
                });

                table.clear();
                table.rows.add(data);
                table.draw();
              });
            }
            setShowDialog(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
