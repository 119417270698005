import React from 'react';

import './styles/modal.css';
import './styles/modalAlert.css';

export default ModalAlert;

function ModalAlert(props) {
  const modalStyle = {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,0.4)'
  }

  return (
    <>
      <div className='modal fade show' aria-hidden='true' style={modalStyle} onClick={props.hide}>
        <div className='modal-dialog' onClick={(e) => e.stopPropagation()}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{props.title}</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Закрыть' onClick={props.hide}></button>
            </div>
            <div className='modal-body'>
              {props.body}
            </div>
            {
              props.func ?
                <div className='modal-footer'>
                  <button type='button' className='btn btn-primary' onClick={props.func}>{props.funcName}</button>
                </div>
                : <></>
            }
            {
              props.message ? 
                <div className='modal-message'>
                  {props.message}
                </div> : null
            }
          </div>
        </div>
      </div>
    </>
  );
}