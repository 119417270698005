import React, { useCallback, useEffect, useState } from 'react';

import Widget from 'components/shared/templates/Widget';
import { fetchWrapper } from '../../../helpers/fetchwrapper';

export default SettingsChecks;

function SettingsChecks(props) {
    const [loadSettings, setLoadSettings] = useState(false);
    const [loadSave, setLoadSave] = useState(false);
    const [motivation1, setMotivation1] = useState(null);
    const [motivation2, setMotivation2] = useState(null);
    const [motivation3, setMotivation3] = useState(null);
    const [motivation4, setMotivation4] = useState(null);
    const [motivation5, setMotivation5] = useState(null);
    const [motivation6, setMotivation6] = useState(null);

    const getSettings = useCallback(() => {
        setLoadSettings(true);
        fetchWrapper.get(`api/checks/getSettings`).then((result) => {
            setMotivation1(result.motivation1);
            setMotivation2(result.motivation2);
            setMotivation3(result.motivation3);
            setMotivation4(result.motivation4);
            setMotivation5(result.motivation5);
            setMotivation6(result.motivation6);
            setLoadSettings(false);
        });
    }, []);

    const saveSettings = () => {
        setLoadSave(true);
        fetchWrapper.post(`api/checks/setSettings`, {
            motivation1: motivation1,
            motivation2: motivation2,
            motivation3: motivation3,
            motivation4: motivation4,
            motivation5: motivation5,
            motivation6: motivation6
        }).then((result) => {
            setLoadSave(false);
        });
    };

    useEffect(() => {
        getSettings();
    }, [getSettings]);

    return (
        <>
            <Widget>
                <h4>Мотивирующие фразы после чеков</h4>
                {
                    loadSettings ?
                        <>
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                        </> :
                        <>
                            <div className='row'>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="motivation">Мотивация для 1</label>
                                    <textarea className="form-control" rows={3} defaultValue={motivation1} onChange={(e) => setMotivation1(e.target.value)}></textarea>
                                </div>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="motivation">Мотивация для 2</label>
                                    <textarea className="form-control" rows={3} defaultValue={motivation2} onChange={(e) => setMotivation2(e.target.value)}></textarea>
                                </div>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="motivation">Мотивация для 3</label>
                                    <textarea className="form-control" rows={3} defaultValue={motivation3} onChange={(e) => setMotivation3(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="motivation">Мотивация для 4</label>
                                    <textarea className="form-control" rows={3} defaultValue={motivation4} onChange={(e) => setMotivation4(e.target.value)}></textarea>
                                </div>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="motivation">Мотивация для 5</label>
                                    <textarea className="form-control" rows={3} defaultValue={motivation5} onChange={(e) => setMotivation5(e.target.value)}></textarea>
                                </div>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="motivation">Мотивация для 6</label>
                                    <textarea className="form-control" rows={3} defaultValue={motivation6} onChange={(e) => setMotivation6(e.target.value)}></textarea>
                                </div>
                            </div>
                            <button disabled={loadSave} className='btn btn-success mt-2' onClick={saveSettings}>
                                {loadSave && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Сохранить
                            </button>
                        </>
                }
            </Widget>
        </>
    );
}
