import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";

import Widget from "components/shared/templates/Widget";
import Modal from "../../../shared/Modal";
import NoRefundsTable from "./NoRefundsTable";

import { fetchWrapper } from "../../../../helpers/fetchwrapper";

const moment = require("moment");
const $ = require("jquery");

const Add = () => {
  return {
    title: "Добавление аккаунта",
    body: (
      <>
        <div className="form-group">
          <label htmlFor="data">Данные</label>
          <input
            type="text"
            className="form-control"
            id="data"
            placeholder="Введите данные"
          />
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="canRefund"
            defaultChecked={true}
          />
          <label className="form-check-label" for="canRefund">
            Возврат разрешен
          </label>
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        data: body.find("#data").val(),
        canRefund: body.find("#canRefund").is(":checked"),
      };

      return fetchWrapper.post("api/norefunds/add", data);
    },
  };
};

const AddMore = () => {
  return {
    title: "Добавление аккаунта",
    body: (
      <>
        <div className="form-group">
          <label htmlFor="data">Данные</label>
          <textarea
            className="form-control"
            id="data"
            placeholder="Введите данные"
            rows="20"
          />
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="canRefund"
            defaultChecked={true}
          />
          <label className="form-check-label" for="canRefund">
            Возврат разрешен
          </label>
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        data: body.find("#data").val(),
        canRefund: body.find("#canRefund").is(":checked"),
      };

      return fetchWrapper.post("api/norefunds/addmany", data);
    },
  };
};

export default NoRefunds;

function NoRefunds(props) {
  const [show, setShow] = useState(false);
  const [constructor, setConstructor] = useState({});
  const [loadForming, setLoadForming] = useState(false);
  const [results, setResults] = useState(null);
  const fromDate = useRef();
  const toDate = useRef();

  const forming = () => {
    setResults(null);
    setLoadForming(true);
    let data = {
      from: $(fromDate.current).val(),
      to: $(toDate.current).val(),
    };
    fetchWrapper.post(`api/norefunds/getbydayall`, data).then((result) => {
      setResults(result);
      setLoadForming(false);
    });
  };

  const block = (account) => {
    if (account) {
      let data = {
        id: account.id,
      };
      fetchWrapper
        .post(`api/norefunds/lock`, data)
        .then((result) => {
          toast.info(result.message);
          forming();
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Не выбран аккаунт");
    }
  };

  useEffect(() => {
    forming();
  }, []);

  return (
    <>
      <Widget>
        <div className="d-sm-flex flex-wrap justify-content-between">
          <div className="d-sm-flex align-items-center">
            <div className="d-flex align-items-center">
              <h4>Аккаунты</h4>
            </div>
          </div>
          <div className="d-sm-flex flex-wrap align-items-center justify-content-end w-100">
            <div className="d-sm-flex flex-wrap align-items-center justify-content-start">
              <button
                className="btn btn-outline-success"
                onClick={() => {
                  setConstructor(Add());
                  setShow(true);
                }}
              >
                Добавить один
              </button>
              <button
                className="btn btn-success ms-2"
                onClick={() => {
                  setConstructor(AddMore());
                  setShow(true);
                }}
              >
                Добавить много
              </button>
            </div>
            <div className="ms-auto d-flex align-items-center justify-content-end gap-2">
              <label>С</label>
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={fromDate}
              ></input>
            </div>
            <div className="ms-auto ms-sm-4 d-flex align-items-center justify-content-end gap-2">
              <label>По</label>
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={toDate}
              ></input>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary ms-auto ms-sm-4"
                onClick={forming}
              >
                Показать
              </button>
            </div>
          </div>
        </div>
        <NoRefundsTable results={results} block={block} />
        {show === true ? (
          <Modal
            hide={() => setShow(false)}
            title={constructor.title}
            body={constructor.body}
            getDataFunc={constructor.getDataFunc}
            saveName={constructor.saveName}
            saveFunc={constructor.saveFunc}
            successFunc={() => {
              toast.info("Аккаунт(ы) добавлен(ы)");
              setShow(false);
              forming();
            }}
          />
        ) : (
          ""
        )}
      </Widget>
    </>
  );
}
