import React, { useCallback, useEffect, useState } from 'react';

import Widget from 'components/shared/templates/Widget';
import { fetchWrapper } from '../../../helpers/fetchwrapper';

export default SettingsRating;

function SettingsRating(props) {
    const [loadSettings, setLoadSettings] = useState(false);
    const [loadSave, setLoadSave] = useState(false);
    const [oneStarManager, setOneStarManager] = useState(null);
    const [twoStarManager, setTwoStarManager] = useState(null);
    const [threeStarManager, setThreeStarManager] = useState(null);
    const [fourStarManager, setFourStarManager] = useState(null);
    const [fiveStarManager, setFiveStarManager] = useState(null);
    const [geniusSellerManager, setGeniusSellerManager] = useState(null);

    const [oneStarSupervisor, setOneStarSupervisor] = useState(null);
    const [twoStarSupervisor, setTwoStarSupervisor] = useState(null);
    const [threeStarSupervisor, setThreeStarSupervisor] = useState(null);
    const [fourStarSupervisor, setFourStarSupervisor] = useState(null);
    const [fiveStarSupervisor, setFiveStarSupervisor] = useState(null);
    const [geniusSellerSupervisor, setGeniusSellerSupervisor] = useState(null);

    const getSettings = useCallback(() => {
        setLoadSettings(true);
        fetchWrapper.get(`api/rating/getSettings`).then((result) => {
            setOneStarManager(result.oneStarManager);
            setTwoStarManager(result.twoStarManager);
            setThreeStarManager(result.threeStarManager);
            setFourStarManager(result.fourStarManager);
            setFiveStarManager(result.fiveStarManager);
            setGeniusSellerManager(result.geniusSellerManager);

            setOneStarSupervisor(result.oneStarSupervisor);
            setTwoStarSupervisor(result.twoStarSupervisor);
            setThreeStarSupervisor(result.threeStarSupervisor);
            setFourStarSupervisor(result.fourStarSupervisor);
            setFiveStarSupervisor(result.fiveStarSupervisor);
            setGeniusSellerSupervisor(result.geniusSellerSupervisor);

            setLoadSettings(false);
        });
    }, []);

    const saveSettings = () => {
        setLoadSave(true);
        fetchWrapper.post(`api/rating/setSettings`, {
            oneStarManager: oneStarManager,
            twoStarManager: twoStarManager,
            threeStarManager: threeStarManager,
            fourStarManager: fourStarManager,
            fiveStarManager: fiveStarManager,
            geniusSellerManager: geniusSellerManager,
            oneStarSupervisor: oneStarSupervisor,
            twoStarSupervisor: twoStarSupervisor,
            threeStarSupervisor: threeStarSupervisor,
            fourStarSupervisor: fourStarSupervisor,
            fiveStarSupervisor: fiveStarSupervisor,
            geniusSellerSupervisor: geniusSellerSupervisor,
        }).then((result) => {
            setLoadSave(false);
        });
    };

    useEffect(() => {
        getSettings();
    }, [getSettings]);

    return (
        <>
            <Widget>
                <h4>Настройка рейтинга</h4>
                {
                    loadSettings ?
                        <>
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                        </> :
                        <>
                            <h5>Менеджеры</h5>
                            <div className='row'>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="oneStarManager">★</label>
                                    <input min={1} type="number" className="form-control" id="oneStarManager" placeholder="Введите количество чеков" defaultValue={oneStarManager} onChange={(e)=>setOneStarManager(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="twoStarManager">★★</label>
                                    <input min={1} type="number" className="form-control" id="twoStarManager" placeholder="Введите количество чеков" defaultValue={twoStarManager} onChange={(e)=>setTwoStarManager(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="threeStarManager">★★★</label>
                                    <input min={1} type="number" className="form-control" id="threeStarManager" placeholder="Введите количество чеков" defaultValue={threeStarManager} onChange={(e)=>setThreeStarManager(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="fourStarManager">★★★★</label>
                                    <input min={1} type="number" className="form-control" id="fourStarManager" placeholder="Введите количество чеков" defaultValue={fourStarManager} onChange={(e)=>setFourStarManager(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="fiveStarManager">★★★★★</label>
                                    <input min={1} type="number" className="form-control" id="fiveStarManager" placeholder="Введите количество чеков" defaultValue={fiveStarManager} onChange={(e)=>setFiveStarManager(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="geniusSellerManager">Гений продаж</label>
                                    <input min={1} type="number" className="form-control" id="geniusSellerManager" placeholder="Введите количество чеков" defaultValue={geniusSellerManager} onChange={(e)=>setGeniusSellerManager(e.target.value)} />
                                </div>
                            </div>
                            <h5 className='mt-2'>Команды</h5>
                            <div className='row'>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="oneStarSupervisor">★</label>
                                    <input min={1} type="number" className="form-control" id="oneStarSupervisor" placeholder="Введите количество чеков" defaultValue={oneStarSupervisor} onChange={(e)=>setOneStarSupervisor(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="twoStarSupervisor">★★</label>
                                    <input min={1} type="number" className="form-control" id="twoStarSupervisor" placeholder="Введите количество чеков" defaultValue={twoStarSupervisor} onChange={(e)=>setTwoStarSupervisor(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="threeStarSupervisor">★★★</label>
                                    <input min={1} type="number" className="form-control" id="threeStarSupervisor" placeholder="Введите количество чеков" defaultValue={threeStarSupervisor} onChange={(e)=>setThreeStarSupervisor(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="fourStarSupervisor">★★★★</label>
                                    <input min={1} type="number" className="form-control" id="fourStarSupervisor" placeholder="Введите количество чеков" defaultValue={fourStarSupervisor} onChange={(e)=>setFourStarSupervisor(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="fiveStarSupervisor">★★★★★</label>
                                    <input min={1} type="number" className="form-control" id="fiveStarSupervisor" placeholder="Введите количество чеков" defaultValue={fiveStarSupervisor} onChange={(e)=>setFiveStarSupervisor(e.target.value)} />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="geniusSellerSupervisor">Гений продаж</label>
                                    <input min={1} type="number" className="form-control" id="geniusSellerSupervisor" placeholder="Введите количество чеков" defaultValue={geniusSellerSupervisor} onChange={(e)=>setGeniusSellerSupervisor(e.target.value)} />
                                </div>
                            </div>
                            <button disabled={loadSave} className='btn btn-success mt-2' onClick={saveSettings}>
                                {loadSave && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Сохранить
                            </button>
                        </>
                }
            </Widget>
        </>
    );
}
