import React, { useCallback, useEffect, useRef, useState } from 'react';

export default RatingHistoryTable;

const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

function RatingHistoryTable(props) {
    const table = useRef();
    const [colors, setColors] = useState([]);

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const loadDataTable = useCallback(() => {
        if (!$.fn.DataTable.isDataTable(table.current)) {
            $(table.current).DataTable({
                data: props.data,
                dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
                language: {
                    "emptyTable": "Нет данных",
                    "info": "Показано _START_ до _END_ из _TOTAL_",
                    "infoEmpty": "Показано 0 до 0 из 0",
                    "infoFiltered": "(Выбрано из _MAX_)",
                    "zeroRecords": "Не найдено",
                    "loadingRecords": "Загрузка...",
                    "search": "Поиск:"
                },
                paging: false,
                searching: false,
                scrollY: 300,
                scrollX: true,
                ordering: false,
                rowCallback: function (row, data, index) {
                    let color = colors.find(p => p.supervisorId === data.supervisorId);
                    if (color) {
                        $('td', row).css('background-color', color.color + '4C');
                    }
                    else {
                        colors[colors.length] = {
                            supervisorId: data.supervisorId,
                            color: getRandomColor()
                        };

                        setColors(colors);

                        $('td', row).css('background-color', colors[colors.length - 1].color + '4C');
                    }
                },
                columns: [
                    {
                        title: 'ФИО',
                        data: null,
                        render: function (t) {
                            return t.lastName + ' ' + t.firstName;
                        }
                    },
                    {
                        title: 'Кол-во чеков за прошлую неделю',
                        data: 'countChecksOldWeek'
                    },
                    {
                        title: 'Кол-во чеков за текущую неделю',
                        data: 'countChecksCurrentWeek'
                    },
                    {
                        title: 'Кол-во звёзд за прошлую неделю',
                        data: 'ratingOldWeek'
                    },
                    {
                        title: 'Кол-во звёзд за текущую неделю',
                        data: 'ratingCurrentWeek'
                    }
                ]
            });
        }
        else {
            $(table.current).DataTable().clear();
            if (props.data) {
                $(table.current).DataTable().rows.add(props.data).draw(true);
            }
        }
    }, [props.data, colors]);

    useEffect(() => {
        loadDataTable();
    }, [loadDataTable]);

    useEffect(() => {
        setTimeout(() => {
            if ($.fn.DataTable.isDataTable(table.current)) {
                $(table.current).DataTable().columns.adjust().draw();
            }
        }, 400);
    });

    return (
        <>
            <table className='w-100 display nowrap' ref={table}></table>
        </>
    );
}
