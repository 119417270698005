import React, { Component } from 'react';
import { Role } from 'helpers/role';
import PrivateComponent from 'components/shared/PrivateComponent';
import NewsFeed from './newsFeed/NewsFeed';

export class News extends Component {
  static displayName = News.name;

  render() {
    return (
      <PrivateComponent>
        <NewsFeed roles={[Role.Manager]} />
      </PrivateComponent>
    );
  }
}
