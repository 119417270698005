import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Widget from "components/shared/templates/Widget";
import ManualCalculateTable from "./ManualCalculateTable";

import { fetchWrapper } from "../../../../helpers/fetchwrapper";

export default ManualCalculate;

function ManualCalculate(props) {
  const [loadForming, setLoadForming] = useState(false);
  const [results, setResults] = useState(null);

  const forming = () => {
    setResults(null);
    setLoadForming(true);
    fetchWrapper
      .get(`api/norefunds/getCheckUsersForManualCalculate`)
      .then((result) => {
        setResults(result);
        setLoadForming(false);
      });
  };

  const manual = () => {
    setLoadForming(true);
    let data = {
      checks: results,
    };
    fetchWrapper
      .post(`api/norefunds/manualCalculate`, data)
      .then((result) => {
        toast.info(result.message);
        setLoadForming(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    forming();
  }, []);

  useEffect(() => {
    console.log(results);
  }, [results]);

  return (
    <>
      <Widget>
        <div className="d-sm-flex flex-wrap justify-content-between">
          <div className="d-sm-flex align-items-center">
            <div className="d-flex align-items-center">
              <h4>Ручной перерасчет</h4>
            </div>
          </div>
          <div className="d-sm-flex flex-wrap align-items-center w-100">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary ms-auto"
                onClick={manual}
                disabled={loadForming}
              >
                Перерасчитать
              </button>
            </div>
          </div>
        </div>
        <ManualCalculateTable results={results} set={setResults} />
      </Widget>
    </>
  );
}
