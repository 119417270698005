import PrivateComponent from "components/shared/PrivateComponent";
import ManagerSalaryTotal from "components/statistic/managerSalary/ManagerSalaryTotal";
import RatingHistory from "components/statistic/ratingHistory/RatingHistory";
import SupervisorSalary from "components/statistic/supervisorSalary/SupervisorSalary";
import SupervisorSalaryByGroups from "components/statistic/supervisorSalary/SupervisorSalaryByGroups";
import SupervisorSalaryTotal from "components/statistic/supervisorSalary/SupervisorSalaryTotal";
import UserSalary from "components/statistic/userSalary/UserSalary";
import { Role } from "helpers/role";

export default Salary;

function Salary(props) {
    return (
        <PrivateComponent>
            <RatingHistory roles={[Role.Accountant]} />
            <UserSalary roles={[Role.Accountant]} />
            <SupervisorSalary roles={[Role.Supervisor]} />
            <SupervisorSalaryByGroups roles={[Role.Supervisor]} />
            <SupervisorSalaryTotal roles={[Role.Supervisor]} />
            <ManagerSalaryTotal roles={[Role.Manager]} />
        </PrivateComponent>
    );
}