import SettingsChecks from 'components/administration/checks/SettingsChecks';
import NewsFeed from 'components/administration/newsfeed/NewsFeed';
import Users from 'components/administration/users/Users';

export default Settings;

function Settings(props) {

    return (
        <>
            <Users />
            <div className='mb-3'></div>
            <SettingsChecks />
            <div className='mb-3'></div>
            <NewsFeed/>
        </>
    );
}