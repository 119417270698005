import PrivateComponent from "components/shared/PrivateComponent";
import { Role } from "helpers/role";
import AllSuccessuf from "./AllSuccessuf/AllSuccessuf";
import Comparer from "./comparer/Comparer";
import Currents from "./currents/Currents";

export default Checks;

function Checks(props) {
  return (
    <PrivateComponent>
      <Currents roles={[Role.Manager]} />
      <Comparer roles={[Role.Accountant]} />
      <AllSuccessuf roles={[Role.Accountant]} />
    </PrivateComponent>
  );
}
