import React, { useCallback, useEffect, useState } from 'react';

import Widget from 'components/shared/templates/Widget';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

import './styles/managerSalaryTotal.css';

import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru');

export default ManagerSalaryTotal;

function ManagerSalaryTotal(props) {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 768;
    const [data, setData] = useState(null);

    const getData = useCallback(() => {
        fetchWrapper.get(`api/statistic/getManagerSalaryTotal`).then(result => {
            setData(result);
        });
    }, []);

    const getTableYear = () => {
        let monthsyear = moment.months();
        let countCell = 0;
        let table = [];
        let row = [];

        monthsyear.forEach(month => {
            let currentData = data.months.find(p => p.index === (row.length + table.length * 3) + 1);
            if (countCell === 3) {
                countCell = 0;
                table.push(<tr key={table.length}>{row}</tr>)
                row = [];
            }
            row.push(
                <td key={month} className={moment().format('MMMM') === month ? 'current' : null}>
                    <div className='month-salary'>{month}</div>
                    <div>Зарплата
                        <span className='ms-1'>{currentData ? currentData.salary : 0}</span>
                    </div>
                    <div>Кол-во чеков
                        <span className='ms-1'>{currentData ? currentData.countChecks : 0}</span>
                    </div>
                </td>);
            countCell++;
        });
        table.push(<tr key={table.length}>{row}</tr>)

        return table;
    };

    const getTableMonth = () => {
        let dateFirst = moment().startOf('month');
        let dateLast = moment().endOf('month');
        let startWeek = dateFirst.week();
        let endWeek = dateLast.week();
        let countWeek = 0;
        let currentWeek = moment().week();
        if (endWeek < startWeek) {
            countWeek = dateFirst.weeksInYear() - startWeek + 1 + endWeek;
            currentWeek = dateFirst.weeksInYear() - startWeek + 1 + currentWeek;
        } else {
            countWeek = endWeek - startWeek + 1;
            currentWeek = currentWeek - startWeek + 1;
        }

        let table = [];

        for (let i = 0; i < countWeek; i++) {
            let currentData = data.weeks.find(p => p.index === i + 1);
            table.push(<tr key={i} className={currentWeek === i + 1 ? 'current' : null}>
                <td key={'Неделя ' + (i + 1)}>{'Неделя ' + (i + 1)}</td>
                <td>{currentData ? currentData.salary : 0}</td>
                <td>{currentData ? currentData.countChecks : 0}</td>
            </tr>);
            if (currentWeek === i + 1) {
                break;
            }
        }

        return table;
    };

    const getTableWeek = (before) => {
        let weekdays = moment.weekdaysShort();
        let startWeek = moment().startOf('week');

        let header = [];
        header.push(<th key={'params'} className='params-header'></th>);
        let salary = [];
        salary.push(<td key={'params'} className='params-header'>Зарплата</td>);
        let countChecks = [];
        countChecks.push(<td key={'params'} className='params-header'>Кол-во чеков</td>);

        if (before) {
            startWeek = startWeek.add(-7, 'days');
            for (let i = 0; i < 7; i++) {
                let currentData = data.beforeDays.find(p => i === 6 ? p.index === 0 : p.index === i + 1);
                header.push(<th key={i}>{weekdays[startWeek.day()]} {startWeek.format('DD.MM')}</th>);
                salary.push(<td key={i}>{currentData ? currentData.salary : 0}</td>);
                countChecks.push(<td key={i}>{currentData ? currentData.countChecks : 0}</td>);
                startWeek.add(1, 'days');
            }
        }
        else {
            for (let i = 0; i < 7; i++) {
                let currentData = data.days.find(p => i === 6 ? p.index === 0 : p.index === i + 1);
                header.push(<th key={i}>{weekdays[startWeek.day()]} {startWeek.format('DD.MM')}</th>);
                salary.push(<td key={i} className={startWeek.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? 'current' : null}>{currentData ? currentData.salary : 0}</td>);
                countChecks.push(<td key={i} className={startWeek.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? 'current' : null}>{currentData ? currentData.countChecks : 0}</td>);
                startWeek.add(1, 'days');
            }
        }

        return (
            <table className='week-table-salary'>
                <thead>
                    <tr>{header}</tr>
                </thead>
                <tbody>
                    <tr>{salary}</tr>
                    <tr>{countChecks}</tr>
                </tbody>
            </table>
        )
    };

    const getTableWeekMobile = (before) => {
        let weekdays = moment.weekdaysShort();
        let startWeek = moment().startOf('week');

        let header = [];
        header.push(<th key={'params'} className='params-header'></th>);
        header.push(<th key={'params-salary'}>Зарплата</th>);
        header.push(<th key={'params-countChecks'}>Кол-во чеков</th>);
        let table = [];
        let row = [];

        if (before) {
            startWeek = startWeek.add(-7, 'days');
            for (let i = 0; i < 7; i++) {
                let currentData = data.beforeDays.find(p => i === 6 ? p.index === 0 : p.index === i + 1);
                row.push(<td key={'dow'} className='params-header'>{weekdays[startWeek.day()]} {startWeek.format('DD.MM')}</td>);
                row.push(<td key={'salary'}>{currentData ? currentData.salary : 0}</td>);
                row.push(<td key={'countChecks'}>{currentData ? currentData.countChecks : 0}</td>);
                table.push(<tr key={i}>{row}</tr>)
                row = [];
                startWeek.add(1, 'days');
            }
        }
        else {
            for (let i = 0; i < 7; i++) {
                let currentData = data.days.find(p => i === 6 ? p.index === 0 : p.index === i + 1);
                row.push(<td key={'dow'} className='params-header'>{weekdays[startWeek.day()]} {startWeek.format('DD.MM')}</td>);
                row.push(<td key={'salary'} className={startWeek.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? 'current' : null}>{currentData ? currentData.salary : 0}</td>);
                row.push(<td key={'countChecks'} className={startWeek.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? 'current' : null}>{currentData ? currentData.countChecks : 0}</td>);
                table.push(<tr key={i}>{row}</tr>)
                row = [];
                startWeek.add(1, 'days');
            }
        }

        return (
            <table className='week-table-salary'>
                <thead>
                    <tr>{header}</tr>
                </thead>
                <tbody>
                    {table}
                </tbody>
            </table>
        )
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <Widget>
            {
                data ?
                    <>
                        <div className='row mb-2'>
                            <div className='col-12 week-salary'>
                                <h4>Статистика за текущую неделю</h4>
                                {isMobile ? getTableWeekMobile(false) : getTableWeek(false)}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12 week-salary'>
                                <h4>Статистика за прошлую неделю</h4>
                                {isMobile ? getTableWeekMobile(true) : getTableWeek(true)}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <h4>Статистика за {moment().format('YYYY')} год</h4>
                                <table className='year-table-salary'>
                                    <tbody>
                                        {getTableYear()}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12 col-md-6'>
                                <h4>Статистика за {moment().format('MMMM')}</h4>
                                <table className='month-table-salary'>
                                    <thead>
                                        <tr>
                                            <th>Неделя</th>
                                            <th>Зарплата</th>
                                            <th>Кол-во чеков</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getTableMonth()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </> : <><span className='spinner-border spinner-border-sm mb-2'></span></>
            }
        </Widget>
    );
}