import React, { Component } from "react";
import styles from "./styles/keypad.module.css";

class Keypad extends Component {
  render() {
    return <div className={styles.keypad}>{this.props.children}</div>;
  }
}

export default Keypad;
