import React, { useCallback, useEffect, useRef } from "react";

const moment = require("moment");

export default NoRefundsLockedByReasonTable;

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function NoRefundsLockedByReasonTable({ results }) {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: results,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        responsive: true,
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[1, "asc"]],
        columns: [
          {
            title: "",
            data: null,
            orderable: false,
            width: 10,
            render: function () {
              return "";
            },
          },
          {
            title: "Дата создания",
            data: "dateCreate",
            width: 100,
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Аккаунт",
            data: "data",
            render: function (d) {
              return (
                d +
                '<div class="a-note-container">' +
                '<div class="a-note a-note-readonly checks"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                '<div class="a-note-content"></div></div></div></div>'
              );
            },
          },
          {
            title: "Когда",
            data: null,
            width: 100,
            render: function (c, s, d) {
              if (d.history.length > 0) {
                return moment(d.history[0].statusDate).format(
                  "DD.MM.YYYY HH:mm"
                );
              }
            },
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (results) {
        $(table.current).DataTable().rows.add(results).draw(true);
      }
    }
  }, [results]);

  const getStatusName = (id) => {
    switch (id) {
      case 1:
        return "Добавил";
      case 2:
        return "Купил";
      case 3:
        return "Вернул";
      default:
        return "Неизвестно";
    }
  };

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    $(table.current).off();

    $(table.current).on("click", ".close", function () {
      $(this).parent().parent().parent().removeClass("is-active");
    });

    $(table.current).on("dblclick", "tr td:nth-child(3)", function () {
      let row = $(table.current).DataTable().row($(this).parent());
      let data = row.data();
      $(this).find("div.a-note-container").addClass("is-active");
      $(this).find("div.a-note-content").empty();
      $(this)
        .find("div.a-note-content")
        .append(
          data.history
            .map(
              (d) =>
                `${moment(d.statusDate).format(
                  "DD.MM.YYYY HH:mm"
                )} ${getStatusName(d.status)} ${d.userData}`
            )
            .join("<br/>")
        );
    });

    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
}
