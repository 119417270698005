import ChecksSupervisors from "./ChecksSupervisors";
import Checks from "./Checks";
import SalarySupervisors from "./salarySupervisors/SalarySupervisors";

export default Totals;

function Totals(props) {
  return (
    <>
      <ChecksSupervisors />
      <div className="mb-3"></div>
      <SalarySupervisors />
      <div className="mb-3"></div>
      <Checks />
    </>
  );
}
