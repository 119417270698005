import React, { useRef, useState } from 'react';

import Widget from 'components/shared/templates/Widget';

import { SupervisorSalaryTable } from './SupervisorSalaryTable'

import { fetchWrapper } from '../../../helpers/fetchwrapper';

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

export default SupervisorSalary;

function SupervisorSalary(props) {
    const [loadForming, setLoadForming] = useState(false);
    const [supervisorSalary, setSupervisorSalary] = useState(null);
    const [tables, setTables] = useState([]);
    const onDate = useRef();

    const formingSupervisorSalary = () => {
        setLoadForming(true);
        setTables([]);
        setSupervisorSalary(null);
        fetchWrapper.get(`api/statistic/getSupervisorSalary?date=${$(onDate.current).val()}`).then((result) => {
            fetchWrapper.get(`api/statistic/getSupervisorSalaryColumns?date=${$(onDate.current).val()}`).then((columns) => {
                result.forEach((el, id, arr) => {
                    el.columns = columns.find((item, index, array) => {
                        if (item.supervisorId === el.supervisorId) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    })

                    if (!el.columns) {
                        el.columns = [];
                    }
                    else {
                        el.columns = el.columns.names;
                    }
                });
                setSupervisorSalary(result);
                setLoadForming(false);
            });
        });
    };

    const getRefForTable = (i, ref) => {
        tables[i] = ref;
    }

    return (
        <div className='mb-2'>
            <Widget>
                <div className='row justify-content-between'>
                    <div className='d-flex align-items-center col-sm-6'>
                        <h4>Расчёт зарплаты</h4>
                        <input disabled={loadForming} id='onDate' type='date' className='ms-4 mt-2 mb-2 form-control' style={{ maxWidth: 150 }} defaultValue={moment().format('YYYY-MM-DD')} ref={onDate}></input>
                    </div>
                    <div className='d-flex align-items-center justify-content-end col-sm-6'>
                        <button disabled={loadForming} className='btn btn-primary ms-md-2' onClick={formingSupervisorSalary}>
                            {loadForming && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Сформировать
                        </button>
                    </div>
                </div>
                {
                    supervisorSalary ?
                        supervisorSalary.map((element, i) => <div className='mb-2' key={i}><SupervisorSalaryTable supervisor={element} ref={getRefForTable} index={i} /></div>)
                        : <></>
                }
            </Widget>
        </div>
    );
}