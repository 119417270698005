import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import Widget from "components/shared/templates/Widget";
import Modal from "components/shared/Modal";
import CurrentsTable from "./CurrentsTable";

import { fetchWrapper } from "helpers/fetchwrapper";

import "./styles/currents.css";

const moment = require("moment");
const $ = require("jquery");

const Refund = (data) => {
  return {
    title: "Возврат аккаунта",
    body: (
      <>
        <div className="form-group">
          <input id="refund-id" type="text" defaultValue={data.id} hidden />
          <label htmlFor="data">Причина возврата</label>
          <select
            id="refund-reason"
            className="form-select"
            aria-label="Выбор причины возврата"
            defaultValue=""
          >
            <option value="">Выберите причину</option>
            <option value="Неверный логин или пароль">
              Неверный логин или пароль
            </option>
            <option value="Просит смс">Просит смс</option>
            <option value="Уже есть выгрузка">Уже есть выгрузка</option>
          </select>
        </div>
      </>
    ),
    saveName: "Вернуть",
    saveFunc: function (body) {
      let data = {
        id: body.find("#refund-id").val(),
        reason: body.find("#refund-reason").val(),
      };

      return fetchWrapper.post("api/accounts/refund", data);
    },
  };
};

export default Currents;

function Currents(props) {
  const [show, setShow] = useState(false);
  const [constructor, setConstructor] = useState({});
  const [loadForming, setLoadForming] = useState(false);
  const [results, setResults] = useState(null);
  const [free, setFree] = useState(0);
  const fromDate = useRef();
  const toDate = useRef();
  const count = useRef();

  const forming = () => {
    setResults(null);
    setLoadForming(true);
    let data = {
      from: $(fromDate.current).val(),
      to: $(toDate.current).val(),
    };
    fetchWrapper.post(`api/accounts/getbyday`, data).then((result) => {
      setResults(result);
      setLoadForming(false);
    });
  };

  const sold = () => {
    if ($(count.current).val()) {
      let data = {
        count: $(count.current).val(),
      };
      fetchWrapper
        .post(`api/accounts/sold`, data)
        .then((result) => {
          toast.info(result);
          getFree();
          forming();
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Введите кол-во");
    }
  };

  const copyAll = (data) => {
    if (data) {
      let copy = "";
      for (let i = 0; i < data.length; i++) {
        copy += data[i].data + "\n";
      }
      navigator.clipboard.writeText(copy);
      toast.info("Скопировано в буфер обмена");
    } else {
      toast.error("Нет данных");
    }
  };

  const getFree = () => {
    fetchWrapper.get(`api/accounts/freeforsold`).then((result) => {
      setFree(result.count);
    });
  };

  const showRefundModal = (data) => {
    setConstructor(Refund(data));
    setShow(true);
  };

  useEffect(() => {
    getFree();
    forming();
  }, []);

  return (
    <>
      <Widget>
        <div className="d-sm-flex flex-wrap justify-content-between">
          <div className="d-sm-flex align-items-center">
            <div className="d-flex align-items-center">
              <h4>Аккаунты</h4>
            </div>
          </div>
          <div className="d-sm-flex flex-wrap align-items-center justify-content-end w-100">
            <div className="d-sm-flex flex-wrap align-items-center justify-content-start">
              <button className="btn btn-success" onClick={sold}>
                Получить
              </button>
              <input
                className="form-control ms-sm-4 mt-2 mb-2"
                placeholder="Введите кол-во"
                type="number"
                style={{ maxWidth: 160 }}
                ref={count}
              ></input>
              <label className="ms-auto ms-sm-2">Доступно {free}</label>
            </div>
            <div className="ms-auto d-flex align-items-center justify-content-end gap-2">
              <label>С</label>
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={fromDate}
              ></input>
            </div>
            <div className="ms-auto ms-sm-4 d-flex align-items-center justify-content-end gap-2">
              <label>По</label>
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={toDate}
              ></input>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary ms-auto ms-sm-4"
                onClick={forming}
              >
                Показать
              </button>
            </div>
          </div>
        </div>
        <CurrentsTable
          results={results}
          copyAll={copyAll}
          refund={showRefundModal}
        />
        {show === true ? (
          <Modal
            hide={() => setShow(false)}
            title={constructor.title}
            body={constructor.body}
            getDataFunc={constructor.getDataFunc}
            saveName={constructor.saveName}
            saveFunc={constructor.saveFunc}
            successFunc={(result) => {
              if (result.result) {
                toast.info("Аккаунт возвращен и выдан новый");
              } else {
                toast.info(
                  "Аккаунт возвращен и новый аккаунт будет выдан, как появится в системе"
                );
              }
              setShow(false);
              forming();
            }}
          />
        ) : (
          ""
        )}
      </Widget>
    </>
  );
}
