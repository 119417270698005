import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '../helpers/history';
import { authActions } from '../store/auth.slice';

import './styles/login.css';
import { Link } from 'react-router-dom';

export default Login;

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const [authError, setAuthError] = useState(null);

    useEffect(() => {
        if (authUser) {
            history.navigate.push('/');
        }
    });

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Логин обязателен'),
        password: Yup.string().required('Пароль обязателен')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password }))
            .unwrap()
            .then(() => {
                const { from } = history.location.state || { from: { pathname: '/' } };
                history.navigate.push(from);
            }).catch((rejectedValueOrSerializedError) => {
                setAuthError(rejectedValueOrSerializedError);
            });
    }

    return (
        <div className='d-flex justify-content-center h-100 align-items-center p-0'>
            <div className="card">
                <h4 className="card-header">Вход</h4>
                <div className="card-body">
                    <form className='mb-2' onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>Логин</label>
                            <input name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                        <div className="form-group">
                            <label>Пароль</label>
                            <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <div className='w-100 d-flex justify-content-center'>
                            <button disabled={isSubmitting} className="btn btn-primary mt-2">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Войти
                            </button>
                        </div>
                    </form>
                    <div className='w-100 d-flex justify-content-center'>
                        <Link to={'/forget-password'}>Забыли пароль?</Link>
                    </div>
                    {authError &&
                        <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                    }
                </div>
            </div>
        </div>
    )
}
