import React from 'react';

import './styles/widget.css';

export default Widget;

function Widget(props) {  
    return (
        <>
            <div className='widget'>
                {props.children}
            </div>
        </>
    );
}
