import Links from "components/administration/links/Links";

export default Page;

function Page(props) {
  return (
    <>
      <Links />
      <div className="mb-3"></div>
    </>
  );
}
