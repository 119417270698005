import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Sidebar } from './Sidebar';
import Navbar from './Navbar';

import './styles/content.css';

export default Layout;

function Layout(props) {
    const { user: authUser } = useSelector(x => x.auth);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [showSidebar, setShowSidebar] = useState(windowDimensions.width <= 900 ? false : true);

    const hideSidebar = () => {
        if (windowDimensions.width <= 900) {
            setShowSidebar(false);
        }
    };

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Navbar setShowSidebar={() => setShowSidebar(!showSidebar)} />
            <Sidebar visible={showSidebar} hide={hideSidebar} roles={authUser.roles} />
            <div className={showSidebar ? 'content sidebar-active' : 'content'}>
                {props.children}
            </div>
        </>
    );
}
