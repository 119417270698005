import React, { useCallback, useEffect, useRef } from 'react';

import { fetchWrapper } from '../../../helpers/fetchwrapper';
import Widget from 'components/shared/templates/Widget';

export default AllSuccessuf;

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

function AllSuccessuf(props) {
    const table = useRef();

    function getData(date) {
        return fetchWrapper.get(`api/checks/fromDateResolved/${date}`);
    }

    const loadDataTable = useCallback(() => {
        if (!$.fn.DataTable.isDataTable(table.current)) {
            $(table.current).DataTable({
                ajax: function (data, callback, settings) {
                    getData(moment().subtract(1, 'days').format('YYYY-MM-DD')).then((result) => {
                        callback({
                            data: result
                        });
                    });
                },
                dom: '<"top"f><"clear">lrtp<"bottom"i>',
                language: {
                    "emptyTable": "Нет данных",
                    "info": "Показано _START_ до _END_ из _TOTAL_",
                    "infoEmpty": "Показано 0 до 0 из 0",
                    "infoFiltered": "(Выбрано из _MAX_)",
                    "zeroRecords": "Не найдено",
                    "loadingRecords": "Загрузка...",
                    "search": "Поиск:"
                },
                paging: false,
                scrollY: 300,
                scrollX: true,
                order: [[1, 'asc']],
                columns: [
                    {
                        title: 'Менеджер',
                        data: null,
                        render: function (t) {
                            return `${t.user.lastName} ${t.user.firstName}`;
                        }
                    },
                    {
                        title: 'Добавлен',
                        data: 'dateCreate',
                        render: function (t) {
                            return moment(t).format('DD.MM.YYYY HH:mm');
                        }
                    },
                    {
                        title: 'Время',
                        data: 'datePay',
                        render: function (t) {
                            return moment(t).format('DD.MM.YYYY HH:mm');
                        }
                    },
                    {
                        title: 'Номер',
                        data: 'number'
                    },
                    {
                        title: 'Сумма',
                        data: 'sum'
                    },
                    {
                        title: 'Реквизиты',
                        data: 'requisites'
                    },
                    {
                        title: 'Инициалы',
                        data: null,
                        render: function (t) {
                            return `${t.lastName} ${t.firstName}`;
                        }
                    },
                    {
                        title: 'Комментарий',
                        data: 'comment'
                    },
                    {
                        title: 'Город',
                        data: 'city'
                    }
                ]
            });

            $($(table.current).DataTable().table().container()).find('.top')
                .append(`<div class="form-group d-flex align-items-center">\
                        <label for="date" style="color: #333;">Дата:</label>\
                        <input type="date" class="form-control" style="border: 1px solid #aaa; border-radius: 3px; margin-left: 3px; width: 140px;" id="dateAllSuccessfulTable" value="${moment().subtract(1, 'days').format('YYYY-MM-DD')}"/>\
                    </div>`);

            $($(table.current).DataTable().table().container()).find('.top #dateAllSuccessfulTable').off().on('change', async function () {
                if ($(this).val()) {
                    let data = await getData($(this).val()).then((result) => {
                        return result;
                    });

                    $(table.current).DataTable().clear();
                    $(table.current).DataTable().rows.add(data);
                    $(table.current).DataTable().draw();
                }
            });
        }
    }, []);

    useEffect(() => {
        loadDataTable();
    }, [loadDataTable]);

    useEffect(() => {
        setTimeout(() => {
            $(table.current).DataTable().columns.adjust().draw();
        }, 400);
    });

    return (
        <>
            <div className='mt-2'></div>
            <Widget>
                <h4>Подтвержденные чеки</h4>
                <table className='w-100 display nowrap' ref={table}></table>
            </Widget>
        </>
    );
}
