import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";

import { useSelector, useDispatch } from "react-redux";

import { history } from "../../helpers/history";
import { fetchWrapper } from "../../helpers/fetchwrapper";
import { authActions } from "../../store/auth.slice";

import "./styles/navbar.css";

const moment = require("moment");

export default Navbar;

function Navbar(props) {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const [notifications, setNotifications] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    fetchWrapper.get(`api/notifications/getunread`).then((result) => {
      setNotifications(result);
    });
  }, []);

  const readAll = () => {
    fetchWrapper.get(`api/notifications/readall`).then((result) => {
      setNotifications([]);
      setShowNotification(false);
    });
  };

  function logout() {
    return dispatch(authActions.logout())
      .unwrap()
      .then(() => {
        history.navigate.push("/login");
      });
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="navbar-burger d-flex align-items-center">
            <FaIcons.FaBars onClick={props.setShowSidebar} />
          </Link>
          <div className="d-flex me-2">
            <div className="me-2 notification">
              <span
                className="notification-icon"
                onClick={() => setShowNotification(!showNotification)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  className="bi bi-bell"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                </svg>
                {notifications.length > 0 ? (
                  <span className="notification-count">
                    {notifications.length}
                  </span>
                ) : null}
              </span>
              {showNotification ? (
                <div className="notification-container">
                  <div className="notification-header">
                    <span>Уведомления</span>
                    <div
                      className="notification-clear"
                      onClick={() => {
                        readAll();
                      }}
                    >
                      Очистить
                    </div>
                  </div>
                  <div className="notification-body">
                    {notifications.length > 0
                      ? notifications.map((notification, index) => {
                          return (
                            <div className="notification-item" key={index}>
                              <div className="notification-text">
                                <p>{notification.message}</p>
                              </div>
                              <div className="notification-date">
                                <p>
                                  {moment(notification.dateCreate).format(
                                    "DD.MM.YYYY HH:mm"
                                  )}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
            <li className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                id="nav-user"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Привет, {authUser.firstName}!
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="#">
                    Обо мне
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    Настройки
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="#" onClick={logout}>
                    Выход
                  </Link>
                </li>
              </ul>
            </li>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}
