import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SidebarData } from './SidebarData';
import { Role } from '../../helpers/role';
import { history } from '../../helpers/history';

export default PrivateRoute;

function PrivateRoute({ children }) {
    const { user: authUser } = useSelector(x => x.auth);

    if (!authUser) {
        return <Redirect to='/login' state={{ from: history.location }} />
    }

    if(SidebarData.find(p=>p.path===history.location.pathname)){
        if(!Role.hasRole(SidebarData.find(p=>p.path===history.location.pathname).roles, authUser.roles)){
            throw new Error('Нет доступа');
        }
    }

    return children;
}