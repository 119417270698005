import React, { useCallback, useEffect, useRef } from "react";

export default ManualCalculateTable;

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function ManualCalculateTable({ results, set }) {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: results,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        responsive: true,
        paging: false,
        scrollX: true,
        order: [[1, "asc"]],
        columns: [
          {
            title: "",
            data: null,
            orderable: false,
            width: 10,
            render: function () {
              return "";
            },
          },
          {
            title: "Менеджер",
            data: "name",
          },
          {
            title: "Кол-во чеков",
            data: "count",
            render: function (t) {
              return (
                "<input type='number' style='width:80px; font-size:14px' name='count' value='" +
                t +
                "'></input>"
              );
            },
          },
        ],
        initComplete: function (settings, json) {
          $(table.current).off();
          $(table.current).on("change", 'input[name="count"]', function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent());
            let data = row.data();
            let temp = parseInt($(this).val());
            if (temp) {
              set((p) => [
                ...p.filter((item) => item.id !== data.id),
                { id: data.id, name: data.name, count: temp },
              ]);
              data.count = temp;
            }
            row.data(data).draw(false);
          });
        },
      });
    } else {
      $(table.current).DataTable().clear();
      if (results) {
        $(table.current).DataTable().rows.add(results).draw(false);
      }
    }
  }, [results, set]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw(false);
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
}
