import React, { useCallback, useEffect, useRef } from 'react';

import { fetchWrapper } from '../../../helpers/fetchwrapper';
import { Requisites } from 'helpers/requisites';

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

$.fn.dataTable.ext.order['dom-timeInput'] = function (settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return Date.parse("01/01/2000 " + $(td).find('input').val());
    });
};

$.fn.dataTable.ext.order['dom-sumInput'] = function (settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return $(td).find('input').val();
    });
};

$.fn.dataTable.ext.order['dom-initialsInput'] = function (settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return $(td).find('input').val();
    });
};

$.fn.dataTable.ext.order['dom-reqSelect'] = function (settings, col) {
    return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return $(td).find('select').val();
    });
};

export const AccountantTable = React.forwardRef((props, ref) => {
    const table = useRef();

    const parseReport = () => {
        let data = new FormData();
        data.append('file', document.getElementById("kaspiReport").files[0]);
        fetchWrapper.post(`api/checks/reportkaspi`, data, false).then((response) => {
            if (response.length > 0) {
                let rows = [];
                for (let i = 0; i < response.length; i++) {
                    rows.push(['00:00', 'Каспи', response[i].sum, response[i].initials]);
                }
                $(table.current).DataTable().rows.add(rows).draw(false);
            }
        });
    };

    const getData = () => {
        return fetchWrapper.get(`api/checks/gettempcomparechecks`);
    }

    const saveData = () => {
        let data = [];
        let rows = $(table.current).find('tr');

        for (let i = 0; i < rows.length; i++) {
            let timePay = $(rows[i]).find('input[name="time"]').val();
            let requisites = $(rows[i]).find('select[name="requisites"]').val();
            let sum = parseInt($(rows[i]).find('input[name="sum"]').val());
            let initials = $(rows[i]).find('input[name="initials"]').val();

            if (timePay && requisites && sum && initials) {
                data[data.length] = {
                    timePay: moment().format('YYYY-MM-DD') + 'T' + timePay,
                    requisites: requisites,
                    sum: sum,
                    initials: initials,
                };
            }
        }

        if (data.length > 0) {
            fetchWrapper.post(`api/checks/addtempcomparechecks`, data, true);
        }
    };

    const removeData = () => {
        let data = [];
        fetchWrapper.post(`api/checks/addtempcomparechecks`, data, true);
        $(table.current).DataTable().clear();
        $(table.current).DataTable().row.add([]).draw();
    };

    const loadDataTable = useCallback(() => {
        $(table.current).DataTable({
            ajax: function (data, callback, settings) {
                getData().then((result) => {
                    callback({
                        data: result
                    });
                });
            },
            dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
            language: {
                "emptyTable": "Нет данных",
                "info": "Показано _START_ до _END_ из _TOTAL_",
                "infoEmpty": "Показано 0 до 0 из 0",
                "infoFiltered": "(Выбрано из _MAX_)",
                "zeroRecords": "Не найдено",
                "loadingRecords": "Загрузка...",
                "search": "Поиск:"
            },
            buttons: {
                dom: {
                    button: {
                        tag: 'button',
                        className: ''
                    }
                },
                buttons: [
                    {
                        text: 'Загрузить отчет из Каспи',
                        className: 'btn btn-success',
                        action: async function (e, dt, node, config) {
                            $('#kaspiReport').trigger('click');
                        }
                    },
                    {
                        text: 'Очистить',
                        className: 'btn btn-danger',
                        action: async function (e, dt, node, config) {
                            removeData();
                        }
                    }
                ]
            },
            paging: false,
            scrollY: 300,
            scrollX: true,
            order: [[0, 'asc']],
            columns: [
                {
                    title: 'Время',
                    data: null,
                    render: function (t) {
                        let item = t.timePay ? moment(t.timePay).format('HH:mm') : t[0];
                        if (item) {
                            return '<input type="time" name="time" style="font-size:14px" value="' + item + '"></input>';
                        }
                        else {
                            return '<input type="time" name="time" style="font-size:14px"></input>';
                        }
                    },
                    orderDataType: 'dom-timeInput'
                },
                {
                    title: 'Реквизиты',
                    data: null,
                    render: function (t) {
                        let item = t.requisites ? t.requisites : t[1];
                        let result = '<select type="text" style="width:80px" name="requisites">';
                        for (let i = 0; i < Requisites.length; i++) {
                            result += '<option value="' + Requisites[i].value + '" ';
                            if (item) {
                                result += item === Requisites[i].value ? 'selected' : '';
                            }
                            result += '>' + Requisites[i].label + '</option>';
                        }
                        result += '</select>';
                        return result;
                    },
                    orderDataType: 'dom-reqSelect',
                    type: 'text'
                },
                {
                    title: 'Сумма',
                    data: null,
                    render: function (t) {
                        let item = t.sum ? t.sum : t[2];
                        if (item) {
                            return '<input type="number" style="width:80px; font-size:14px" name="sum" value="' + item + '"></input>';
                        }
                        else {
                            return '<input type="number" style="width:80px; font-size:14px" name="sum"></input>';
                        }
                    },
                    orderDataType: 'dom-sumInput'
                },
                {
                    title: 'Инициалы',
                    data: null,
                    render: function (t) {
                        let item = t.initials ? t.initials : t[3];
                        if (item) {
                            return '<input type="text" name="initials" style="font-size:14px" value="' + item + '"></input>';
                        }
                        else {
                            return '<input type="text" name="initials" style="font-size:14px"></input>';
                        }
                    },
                    orderDataType: 'dom-initialsInput',
                    type: 'text'
                }
            ],
            initComplete: function (settings, json) {
                $(table.current).DataTable().row.add([]).draw(false);
            }
        });

        $(table.current).on('change', 'input:not([type="time"])', function () {
            if ($(table.current).DataTable().row($(this).parent().parent()).index() + 1 === $(table.current).DataTable().rows().count()) {
                $(table.current).DataTable().row.add([]).draw(false);
            }
            saveData();
        })
        $(table.current).on('blur', 'input[type="time"]', function () {
            if ($(this).val().length === 5) {
                if ($(table.current).DataTable().row($(this).parent().parent()).index() + 1 === $(table.current).DataTable().rows().count()) {
                    $(table.current).DataTable().row.add([]).draw(false);
                }
                saveData();
            }
        })

        $(table.current).on('change', 'select', function () {
            if ($(table.current).DataTable().row($(this).parent().parent()).index() + 1 === $(table.current).DataTable().rows().count()) {
                $(table.current).DataTable().row.add([]).draw(false);
            }
            saveData();
        })

        $(table.current).on('click', 'tr', function () {
            $(table.current).find('tr').removeClass('selected');
            $(this).addClass('selected');
        })
    }, []);

    useEffect(() => {
        loadDataTable();
    }, [loadDataTable]);

    useEffect(() => {
        setTimeout(() => {
            $(table.current).DataTable().columns.adjust().draw();
        }, 400);
    });

    useEffect(() => {
        typeof ref === 'function' ? ref(table.current) : (ref.current = table.current);
        return () => typeof ref === 'function' ? ref(null) : (ref.current = null);
    }, [table, ref])

    return (
        <>
            <input type='file' id='kaspiReport' onChange={parseReport} hidden></input>
            <table className='w-100 display nowrap' ref={table}></table>
        </>
    );
});
