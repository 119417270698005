import React, { useRef, useState } from 'react';

import Widget from 'components/shared/templates/Widget';

import ChecksManagersTable from './SalarySupervisorsTable';

import { fetchWrapper } from 'helpers/fetchwrapper';

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

export default SalarySupervisors;

function SalarySupervisors(props) {
    const [loadForming, setLoadForming] = useState(false);
    const [salarySupervisors, setSalarySupervisors] = useState(null);
    const fromDate = useRef();

    const forming = () => {
        setSalarySupervisors(null);
        setLoadForming(true);
        fetchWrapper.get(`api/statistic/getSalarySupervisors?date=${$(fromDate.current).val()}`).then((result) => {
            setSalarySupervisors(result);
            setLoadForming(false);
        });
    };

    return (
        <div className='mb-2'>
            <Widget>
                <div className='row justify-content-between'>
                    <div className='d-sm-flex align-items-center col-sm-6'>
                        <div className='d-flex align-items-center'>
                            <h4>Зарплата</h4>
                        </div>
                        <div className='d-flex align-items-center'>
                            <input disabled={loadForming} id='fromDate' type='date' className='ms-auto ms-sm-4 mt-2 mb-2 form-control' style={{ maxWidth: 150 }} defaultValue={moment().format('YYYY-MM-DD')} ref={fromDate}></input>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end col-sm-6'>
                        <button disabled={loadForming} className='btn btn-primary ms-2' onClick={forming}>
                            {loadForming && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Сформировать
                        </button>
                    </div>
                </div>
                <ChecksManagersTable data={salarySupervisors} />
            </Widget>
        </div>
    );
}