import React from 'react';
import Select from 'react-select'

import Widget from 'components/shared/templates/Widget';
import UsersTable from './UsersTable';

import { fetchWrapper } from '../../../helpers/fetchwrapper';
import { Role } from '../../../helpers/role';

export const Add = async () => {
    let choosedRoles = [];
    let supervisors = (await fetchWrapper.get(`api/users/allbyrole/${Role.Supervisor.value}`)).map(
        supervisor => ({
            value: supervisor.id,
            label: `${supervisor.lastName} ${Array.from(supervisor.firstName)[0]}${Array.from(supervisor.middleName)[0]}`
        }));
    return ({
        title: 'Добавление пользователя',
        body:
            <>
                <div className="form-group">
                    <label htmlFor="lastName">Фамилия</label>
                    <input type="text" className="form-control" id="lastName" placeholder="Введите фамилию" />
                </div>
                <div className="form-group">
                    <label htmlFor="firstName">Имя</label>
                    <input type="text" className="form-control" id="firstName" placeholder="Введите имя" />
                </div>
                <div className="form-group">
                    <label htmlFor="middleName">Отчество</label>
                    <input type="text" className="form-control" id="middleName" placeholder="Введите отчество" />
                </div>
                <div className="form-group">
                    <label htmlFor="middleName">Email</label>
                    <input type="text" className="form-control" id="email" placeholder="Введите Email" />
                </div>
                <div className="form-group">
                    <label htmlFor="username">Логин</label>
                    <input type="text" className="form-control" id="username" placeholder="Введите логин" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Пароль</label>
                    <input type="password" className="form-control" id="password" placeholder="Введите пароль" />
                </div>
                <div className='form-group'>
                    <label htmlFor="roles">Роли</label>
                    <Select
                        id='roles'
                        options={Role.All}
                        isMulti={true}
                        onChange={(e) => choosedRoles = e}
                        placeholder="Выберите роли"
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="supervisors">Куратор</label>
                    <Select
                        id='supervisors'
                        options={supervisors}
                        isMulti={false}
                        onChange={(e) => supervisors = e}
                        placeholder="Выберите куратора"
                        maxMenuHeight={150}
                    />
                </div>
            </>,
        saveName: 'Добавить',
        saveFunc: function (body) {
            let data = {
                lastName: body.find('#lastName').val(),
                firstName: body.find('#firstName').val(),
                middleName: body.find('#middleName').val(),
                email: body.find('#email').val(),
                username: body.find('#username').val(),
                password: body.find('#password').val(),
                roles: choosedRoles.map(p => p.value),
                supervisor: supervisors.value
            };

            return fetchWrapper.post('api/users/add', data);
        }
    });
}

export const Edit = async (id) => {
    let choosed = await fetchWrapper.get(`api/users/${id}`);
    let choosedRoles = choosed.roles.map(role => ({ value: role.id, label: role.name }));
    let supervisors = (await fetchWrapper.get(`api/users/allbyrole/${Role.Supervisor.value}`)).map(
        supervisor => ({
            value: supervisor.id,
            label: `${supervisor.lastName} ${supervisor.firstName ? Array.from(supervisor.firstName)[0] : ''}${supervisor.middleName ? Array.from(supervisor.middleName)[0] : ''}`
        }));
    return ({
        title: 'Изменение пользователя',
        body:
            <>
                <input type="hidden" id="id" value={id} />
                <div className="form-group">
                    <label htmlFor="lastName">Фамилия</label>
                    <input type="text" className="form-control" id="lastName" placeholder="Введите фамилию" defaultValue={choosed.lastName} />
                </div>
                <div className="form-group">
                    <label htmlFor="firstName">Имя</label>
                    <input type="text" className="form-control" id="firstName" placeholder="Введите имя" defaultValue={choosed.firstName} />
                </div>
                <div className="form-group">
                    <label htmlFor="middleName">Отчество</label>
                    <input type="text" className="form-control" id="middleName" placeholder="Введите отчество" defaultValue={choosed.middleName} />
                </div>
                <div className="form-group">
                    <label htmlFor="middleName">Email</label>
                    <input type="text" className="form-control" id="email" placeholder="Введите Email" defaultValue={choosed.email} />
                </div>
                <div className="form-group">
                    <label htmlFor="username">Логин</label>
                    <input type="text" className="form-control" id="username" placeholder="Введите логин" defaultValue={choosed.username} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Пароль</label>
                    <input type="password" className="form-control" id="password" placeholder="Введите пароль" />
                </div>
                <div className='form-group'>
                    <label htmlFor="roles">Роли</label>
                    <Select
                        id='roles'
                        defaultValue={
                            Role.All.filter(role =>
                                choosed.roles.filter(item => item.id === role.value).length > 0)
                        }
                        options={Role.All}
                        isMulti={true}
                        onChange={(e) => choosedRoles = e}
                        placeholder="Выберите роли"
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="supervisors">Куратор</label>
                    <Select
                        id='supervisors'
                        defaultValue={
                            supervisors.filter(supervisor => supervisor.value === choosed.supervisorId)
                        }
                        options={supervisors}
                        isMulti={false}
                        onChange={(e) => choosed.supervisorId = e.value}
                        placeholder="Выберите куратора"
                        maxMenuHeight={150}
                    />
                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="dissmised" defaultChecked={choosed.isDismissed ? true : false}/>
                    <label className="form-check-label" htmlFor="dissmised">Уволен</label>
                </div>
            </>
        ,
        saveName: 'Сохранить',
        saveFunc: function (body) {
            let data = {
                id: body.find('#id').val(),
                lastName: body.find('#lastName').val(),
                firstName: body.find('#firstName').val(),
                middleName: body.find('#middleName').val(),
                email: body.find('#email').val(),
                username: body.find('#username').val(),
                password: body.find('#password').val(),
                roles: choosedRoles.map(p => p.value),
                supervisor: choosed.supervisorId,
                isDismissed: body.find('#dissmised').prop('checked')
            };

            return fetchWrapper.post('api/users/edit', data);
        }
    });
}

export default Users;

function Users(props) {

    return (
        <>
            <Widget>
                <h4>Пользователи</h4>
                <UsersTable />
            </Widget>
        </>
    );
}
