import React from "react";
import * as TbIcons from "react-icons/tb";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

import { Role } from "../../helpers/role";

export const SidebarData = [
  {
    title: "Новости",
    path: "/",
    icon: <TbIcons.TbNews />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: "sidebar-text",
    roles: [Role.All],
  },
  {
    title: "Чеки",
    path: "/checks",
    icon: <TbIcons.TbReceipt />,
    cName: "sidebar-text",
    roles: [Role.Manager, Role.Accountant],
  },
  {
    title: "Лиды",
    path: "/leads",
    icon: <TbIcons.TbPhoneCall />,
    cName: "sidebar-text",
    roles: [Role.Manager],
  },
  {
    title: "Аккаунты",
    path: "/accounts",
    icon: <TbIcons.TbLogin />,
    cName: "sidebar-text",
    roles: [Role.Manager],
  },
  {
    title: "Без замены",
    path: "/norefunds",
    icon: <TbIcons.TbLogin />,
    cName: "sidebar-text",
    roles: [Role.Manager],
  },
  {
    title: "Статистика",
    path: "/statistic",
    icon: <TbIcons.TbCalendarStats />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: "sidebar-text",
    roles: [Role.All],
    subNav: [
      {
        title: "Основное",
        path: "/statistic/main",
        icon: <IoIcons.IoMdTrendingUp />,
        cName: "sidebar-text",
        roles: [Role.Admin, Role.Accountant],
      },
      {
        title: "Зарплата",
        path: "/statistic/salary",
        icon: <IoIcons.IoMdPaper />,
        cName: "sidebar-text",
        roles: [Role.Accountant, Role.Supervisor, Role.Manager],
      },
    ],
  },
  {
    title: "Материалы",
    path: "/materials",
    icon: <TbIcons.TbBox />,
    cName: "sidebar-text",
    roles: [],
  },
  {
    title: "Администрирование",
    path: "/administration",
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: "sidebar-text",
    roles: [Role.Admin],
    subNav: [
      {
        title: "Настройки",
        path: "/administration/settings",
        icon: <IoIcons.IoMdSettings />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
      {
        title: "Проверки",
        path: "/administration/needcheck",
        icon: <IoIcons.IoMdCheckmark />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
      {
        title: "Статистика",
        path: "/administration/statistic",
        icon: <IoIcons.IoMdStats />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
      {
        title: "Лиды",
        path: "/administration/leads",
        icon: <IoIcons.IoMdPhonePortrait />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
      {
        title: "Аккаунты",
        path: "/administration/accounts",
        icon: <IoIcons.IoMdLogIn />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
      {
        title: "Без замены",
        path: "/administration/norefunds",
        icon: <IoIcons.IoMdLogIn />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
      {
        title: "Ссылки",
        path: "/administration/links",
        icon: <IoIcons.IoMdMove />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
      {
        title: "Итоги",
        path: "/administration/totals",
        icon: <IoIcons.IoMdTrendingUp />,
        cName: "sidebar-text",
        roles: [Role.Admin],
      },
    ],
  },
];
