import React, { useCallback, useEffect, useRef } from 'react';

import './styles/supervisorSalaryTable.css'

const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.buttons = require('datatables.net-buttons');

export const SupervisorSalaryTable = React.forwardRef((props, ref) => {
    const table = useRef();

    const getColumns = useCallback(() => {
        let columns = [];
        addColumn(columns, '№', null, 'text-center', function (data, type, row, meta) {
            if (data.counter) {
                return data.counter;
            }
            else {
                data.counter = meta.row + meta.settings._iDisplayStart + 1;
                return data.counter;
            }
        });
        addColumn(columns, '%', 'salarySupervisorParent', 'bg-lightpink text-center', function (t) {
            return t;
        });
        addColumn(columns, 'Доход куратора', 'salarySupervisor', 'bg-lightpink text-center', function (t) {
            return t;
        });
        addColumn(columns, 'Имя', 'name', 'bg-lightblue', function (t) {
            if (t === 'total') {
                return '';
            }
            else {
                return t + '<div class="a-note-container">' +
                    '<div class="a-note-view a-note-readonly"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                    '<div class="a-note-content"></div></div></div>';
            }
        });
        addColumn(columns, 'Зарплата менеджера', 'salary', 'bg-lightpink text-center', function (t) {
            return t;
        });
        addColumn(columns, 'Кол-во чеков', 'countChecks', 'bg-lightpink text-center', function (t) {
            return t;
        });

        if (props.supervisor.columns) {
            props.supervisor.columns.forEach((el, id, arr) => {
                addColumn(columns, el + ' чек', null, 'bg-lightgreen text-center', function (t) {
                    if (t.nominals[el]) {
                        return t.nominals[el];
                    }
                    else {
                        return 0;
                    }
                });
            });
        }

        addColumn(columns, 'Налог на Kaspi RED', 'taxKaspiRed', 'bg-lightpink text-center', function (t) {
            if (t === 'total') {
                return props.supervisor.statistics.reduce((t, a) => t + a.taxKaspiRed, 0);
            }
            else {
                return t + '<div class="a-note-container">' +
                    '<div class="a-note-view"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                    '<div class="a-note-content"></div></div></div>';
            }
        });
        addColumn(columns, 'Количество акк-ов OLX', 'countAccountsOLX', 'bg-lightpink text-center', function (t) {
            if (t === 'total') {
                return props.supervisor.statistics.reduce((t, a) => t + a.countAccountsOLX, 0);
            }
            else {
                return t + '<div class="a-note-container">' +
                    '<div class="a-note-view"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                    '<div class="a-note-content"></div></div></div>';
            }
        });
        addColumn(columns, 'Количество акк-ов Krisha', 'countAccountsKrisha', 'bg-lightpink text-center', function (t) {
            if (t === 'total') {
                return props.supervisor.statistics.reduce((t, a) => t + a.countAccountsKrisha, 0);
            }
            else {
                return t + '<div class="a-note-container">' +
                    '<div class="a-note-view"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                    '<div class="a-note-content"></div></div></div>';
            }
        });
        addColumn(columns, 'Бонус за рекламу', 'bonusAds', 'bg-lightpink text-center', function (t) {
            if (t === 'total') {
                return props.supervisor.statistics.reduce((t, a) => t + a.bonusAds, 0);
            }
            else {
                return t + '<div class="a-note-container">' +
                    '<div class="a-note-view"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                    '<div class="a-note-content"></div></div></div>';
            }
        });
        addColumn(columns, 'Бонусы', 'bonus', 'bg-lightpink text-center', function (t) {
            if (t === 'total') {
                return '';
            }
            else {
                return t + '<div class="a-note-container">' +
                    '<div class="a-note-view"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                    '<div class="a-note-content"></div></div></div>';
            }
        });
        addColumn(columns, 'Штраф', 'payFine', 'bg-lightpink text-center', function (t) {
            if (t === 'total') {
                return '';
            }
            else {
                return t + '<div class="a-note-container">' +
                    '<div class="a-note-view"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
                    '<div class="a-note-content"></div></div></div>';
            }
        });

        return columns;
    }, [props.supervisor]);

    const addColumn = (columns, title, data, className, render) => {
        columns[columns.length] = {
            title: title,
            data: data,
            className: className,
            render: render
        }
    }

    const showTotals = useCallback(() => {
        if ($.fn.DataTable.isDataTable(table.current)) {
            let info = $(table.current).DataTable().data();

            let infoWithoutTotals = info.filter((e) => {
                return e.counter !== '<b>Итог:</b>';
            });

            let data = {
                counter: '<b>Итог:</b>',
                salarySupervisorParent: infoWithoutTotals.reduce((t, a) => t + a.salarySupervisorParent, 0),
                salarySupervisor: infoWithoutTotals.reduce((t, a) => t + a.salarySupervisor, 0),
                name: 'total',
                salary: infoWithoutTotals.reduce((t, a) => t + a.salary, 0),
                countChecks: infoWithoutTotals.reduce((t, a) => t + a.countChecks, 0),
                taxKaspiRed: 'total',
                countAccountsOLX: 'total',
                countAccountsKrisha: 'total',
                bonusAds: 'total',
                bonus: 'total',
                payFine: 'total'
            };

            if (props.supervisor.columns) {
                data.nominals = {};
                props.supervisor.columns.forEach((el, id, arr) => {
                    data.nominals[el] = infoWithoutTotals.reduce((t, a) => t + (a.nominals[el] ? a.nominals[el] : 0), 0)
                });
            }

            if (infoWithoutTotals.length !== info.length) {
                $(table.current).DataTable().row(info.length - 1).data(data).draw(false);
            }
            else {
                $(table.current).DataTable().row.add(data).draw(false);
            }
        }
    }, [props.supervisor.columns]);

    const loadDataTable = useCallback(() => {
        if (!$.fn.DataTable.isDataTable(table.current)) {
            $(table.current).DataTable({
                data: props.supervisor.statistics,
                dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
                language: {
                    "emptyTable": "Нет данных",
                    "info": "Показано _START_ до _END_ из _TOTAL_",
                    "infoEmpty": "Показано 0 до 0 из 0",
                    "infoFiltered": "(Выбрано из _MAX_)",
                    "zeroRecords": "Не найдено",
                    "loadingRecords": "Загрузка...",
                    "search": "Поиск:"
                },
                info: false,
                autoWidth: true,
                searching: false,
                paging: false,
                scrollY: 300,
                scrollX: true,
                ordering: false,
                columns: getColumns(),
                initComplete: function (settings, json) {
                    showTotals();

                    $(table.current).on('click', '.close', function () {
                        $(this).parent().parent().parent().removeClass('is-active');
                    });

                    $(table.current).on('dblclick', 'tr td:nth-child(3)', function () {
                        let row = $(table.current).DataTable().row($(this).parent());
                        let data = row.data();
                        $(this).find('div.a-note-container').addClass('is-active');
                        $(this).find('div.a-note-content').text(data.numericChecks);
                    });

                    $(table.current).on('dblclick', 'tr td:nth-child(7)', function () {
                        let row = $(table.current).DataTable().row($(this).parent());
                        let data = row.data();
                        $(this).find('div.a-note-container').addClass('is-active');
                        $(this).find('div.a-note-content').text(data.taxKaspiRedComment);
                    });

                    $(table.current).on('dblclick', 'tr td:nth-child(8)', function () {
                        let row = $(table.current).DataTable().row($(this).parent());
                        let data = row.data();
                        $(this).find('div.a-note-container').addClass('is-active');
                        $(this).find('div.a-note-content').text(data.countAccountsOLXComment);
                    });

                    $(table.current).on('dblclick', 'tr td:nth-child(9)', function () {
                        let row = $(table.current).DataTable().row($(this).parent());
                        let data = row.data();
                        $(this).find('div.a-note-container').addClass('is-active');
                        $(this).find('div.a-note-content').text(data.countAccountsKrishaComment);
                    });

                    $(table.current).on('dblclick', 'tr td:nth-child(10)', function () {
                        let row = $(table.current).DataTable().row($(this).parent());
                        let data = row.data();
                        $(this).find('div.a-note-container').addClass('is-active');
                        $(this).find('div.a-note-content').text(data.bonusAdsComment);
                    });

                    $(table.current).on('dblclick', 'tr td:nth-child(11)', function () {
                        let row = $(table.current).DataTable().row($(this).parent());
                        let data = row.data();
                        $(this).find('div.a-note-container').addClass('is-active');
                        $(this).find('div.a-note-content').text(data.bonusComment);
                    });

                    $(table.current).on('dblclick', 'tr td:nth-child(12)', function () {
                        let row = $(table.current).DataTable().row($(this).parent());
                        let data = row.data();
                        $(this).find('div.a-note-container').addClass('is-active');
                        $(this).find('div.a-note-content').text(data.payFineComment);
                    });
                }
            });
        }
    }, [props, getColumns, showTotals]);

    useEffect(() => {
        loadDataTable();
    }, [loadDataTable]);

    useEffect(() => {
        setTimeout(() => {
            $(table.current).DataTable().columns.adjust().draw();
        }, 400);
    });

    useEffect(() => {
        typeof ref === 'function' ? ref(props.index, table.current) : (ref.current = table.current);
        return () => typeof ref === 'function' ? ref(props.index, null) : (ref.current = null);
    }, [table, ref, props.index])

    return (
        <div className='userSalaryTable'>
            <h5>{props.supervisor.supervisorName}</h5>
            <table className='w-100 display nowrap' ref={table}></table>
        </div>
    );
});
