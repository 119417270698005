import React, { useCallback, useEffect, useRef, useState } from "react";

import { Add } from "./Leads";
import Modal from "../../shared/Modal";

const moment = require("moment");

export default LeadsTable;

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function LeadsTable({ results, updateDataTable }) {
  const [show, setShow] = useState(false);
  const [constructor, setConstructor] = useState({});
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: results,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        buttons: {
          dom: {
            button: {
              tag: "button",
              className: "",
            },
          },
          buttons: [
            {
              text: "Добавить",
              className: "btn btn-success",
              action: async function (e, dt, node, config) {
                setConstructor(await Add());
                setShow(true);
              },
            },
          ],
        },
        responsive: true,
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[1, "asc"]],
        columns: [
          {
            title: "",
            data: null,
            orderable: false,
            width: 10,
            render: function () {
              return "";
            },
          },
          {
            title: "Дата создания",
            data: "dateCreate",
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Телефон",
            data: "phone",
          },
          {
            title: "Куплен",
            data: "soldPrice",
            render: function (p) {
              return p > 0 ? "Да" : "Нет";
            },
          },
          {
            title: "Кем",
            data: null,
            render: function (p, t, d) {
              return d.soldUser
                ? `${d.soldUser.lastName} ${d.soldUser.firstName}`
                : "-";
            },
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (results) {
        $(table.current).DataTable().rows.add(results).draw(true);
      }
    }
  }, [results]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
      {show === true ? (
        <Modal
          hide={() => setShow(false)}
          title={constructor.title}
          body={constructor.body}
          getDataFunc={constructor.getDataFunc}
          saveName={constructor.saveName}
          saveFunc={constructor.saveFunc}
          successFunc={() => {
            setShow(false);
            updateDataTable();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
