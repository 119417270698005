import React from 'react';

import Widget from 'components/shared/templates/Widget';
import ChecksTable from './ChecksTable';

export default Checks;

function Checks(props) {
    return (
        <>
            <Widget>
                <h4>Проверка чеков</h4>
                <div>
                    <ChecksTable/>
                </div>
            </Widget>
        </>
    );
}
