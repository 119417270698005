import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import Widget from "components/shared/templates/Widget";
import LinksTable from "./LinksTable";
import LinksTableFull from "./LinksTableFull";

import { fetchWrapper } from "../../../helpers/fetchwrapper";

const $ = require("jquery");

export const Add = async () => {
  return {
    title: "Добавление ссылки",
    body: (
      <>
        <div className="form-group">
          <label htmlFor="name">Название</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Введите название"
          />
          <label htmlFor="data">Ссылка</label>
          <input
            type="text"
            className="form-control"
            id="data"
            placeholder="Введите ссылку"
          />
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        name: body.find("#name").val(),
        data: body.find("#data").val(),
      };

      return fetchWrapper.post("api/links/add", data);
    },
  };
};

export default Links;

function Links(props) {
  const { user: authUser } = useSelector((x) => x.auth);
  const [results, setResults] = useState(null);
  const fromDate = useRef();

  const forming = () => {
    setResults(null);
    let data = {
      date: $(fromDate.current).val(),
    };
    fetchWrapper.post(`api/links/getall`, data).then((result) => {
      setResults(result);
    });
  };

  const remove = (link) => {
    let data = {
      id: link.id,
    };
    fetchWrapper.post(`api/links/remove`, data).then((result) => {
      forming();
    });
  };

  useEffect(() => {
    forming();
  }, []);

  return (
    <>
      <Widget>
        <div className="row justify-content-between">
          <div className="d-sm-flex align-items-center col-sm-6">
            <div className="d-flex align-items-center">
              <h4>Ссылки</h4>
            </div>
          </div>
        </div>
        {authUser.id === 1 ? (
          <LinksTableFull
            results={results}
            updateDataTable={forming}
            remove={remove}
          />
        ) : (
          <LinksTable results={results} updateDataTable={forming} />
        )}
      </Widget>
    </>
  );
}
