import React, { useRef, useState } from 'react';

import Widget from 'components/shared/templates/Widget';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

export default Total;

function Total(props) {
    const [loadForming, setLoadForming] = useState(false);
    const [secondChecks, setSecondChecks] = useState(null);
    const [fifthAndTenChecks, setFifthAndTenChecks] = useState(null);
    const [totalChecks, setTotalChecks] = useState(null);
    const [percentChecks, setPercentChecks] = useState(null);
    const fromDate = useRef();
    const toDate = useRef();

    const forming = () => {
        setLoadForming(true);
        fetchWrapper.post(`api/statistic/getThirdChecks?from=${$(fromDate.current).val()}&to=${$(toDate.current).val()}`).then((result) => {
            setSecondChecks(result);
            fetchWrapper.post(`api/statistic/getFifthAndTenChecks?from=${$(fromDate.current).val()}&to=${$(toDate.current).val()}`).then((result) => {
                setFifthAndTenChecks(result);
                fetchWrapper.post(`api/statistic/getTotalChecks?from=${$(fromDate.current).val()}&to=${$(toDate.current).val()}`).then((result) => {
                    setTotalChecks(result);
                    fetchWrapper.post(`api/statistic/getPersentChecks?from=${$(fromDate.current).val()}&to=${$(toDate.current).val()}`).then((result) => {
                        setPercentChecks(result);
                        setLoadForming(false);
                    });
                });
            });
        });
    };

    return (
        <div className='mb-2'>
            <Widget>
                <div className='row justify-content-between'>
                    <div className='d-sm-flex align-items-center col-sm-6'>
                        <div className='d-flex align-items-center'>
                            <h4>Итоги</h4>
                        </div>
                        <div className='d-flex align-items-center'>
                            <input disabled={loadForming} id='fromDate' type='date' className='ms-auto ms-sm-4 mt-2 mb-2 form-control' style={{ maxWidth: 150 }} defaultValue={moment().format('YYYY-MM-DD')} ref={fromDate}></input>
                            <input disabled={loadForming} id='toDate' type='date' className='ms-2 me-auto mt-2 mb-2 form-control' style={{ maxWidth: 150 }} defaultValue={moment().format('YYYY-MM-DD')} ref={toDate}></input>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end col-sm-6'>
                        <button disabled={loadForming} className='btn btn-primary ms-2' onClick={forming}>
                            {loadForming && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Сформировать
                        </button>
                    </div>
                </div>
                <div className='row'>
                    {
                        secondChecks ?
                            <div className='col-sm-12 col-md-4 mb-2'>
                                <h5>3-х чеков</h5>
                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {
                                        secondChecks.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {item.sum} - {item.count}
                                                </li>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        fifthAndTenChecks ?
                            <div className='col-sm-12 col-md-4 mb-2'>
                                <h5>5-х и 10-х чеков</h5>
                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {
                                        fifthAndTenChecks.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {item.sum} - {item.count}
                                                </li>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        totalChecks ?
                            <div className='col-sm-12 col-md-4 mb-2'>
                                <h5>Итог чеков</h5>
                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {
                                        totalChecks.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {item.sum} - {item.count}
                                                </li>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        percentChecks ?
                            <div className='col-12'>
                                <div>
                                    Общая сумма 10% по всем командам за выбранный период:
                                    <h5>{percentChecks.persent}</h5>
                                </div>
                            </div>
                            : <></>
                    }
                </div>
            </Widget >
        </div >
    );
}