import React, { useCallback, useEffect, useRef } from 'react';

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

export const ComparerTable = React.forwardRef((props, ref) => {
    const table = useRef();

    const loadDataTable = useCallback(() => {
        $(table.current).DataTable({
            data: null,
            dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
            language: {
                "emptyTable": "Нет данных",
                "info": "Показано _START_ до _END_ из _TOTAL_",
                "infoEmpty": "Показано 0 до 0 из 0",
                "infoFiltered": "(Выбрано из _MAX_)",
                "zeroRecords": "Не найдено",
                "loadingRecords": "Загрузка...",
                "search": "Поиск:"
            },
            buttons: {
                dom: {
                    button: {
                        tag: 'button',
                        className: ''
                    }
                }
            },
            paging: false,
            scrollY: 300,
            scrollX: true,
            order: [[1, 'asc']],
            columns: [
                {
                    title: 'Время',
                    data: 'accountant.time'
                },
                {
                    title: 'Реквизиты',
                    data: 'accountant.requisites'
                },
                {
                    title: 'Сумма',
                    data: 'accountant.sum'
                },
                {
                    title: 'Инициалы',
                    data: 'accountant.initials'
                },
                {
                    title: 'Менеджер',
                    data: null,
                    render: function (t) {
                        return `${t.user.lastName} ${t.user.firstName}`;
                    }
                },
                {
                    title: 'Время',
                    data: 'datePay',
                    render: function (t) {
                        return moment(t).format('DD.MM.YYYY HH:mm');
                    }
                },
                {
                    title: 'Номер',
                    data: 'number'
                },
                {
                    title: 'Сумма',
                    data: 'sum'
                },
                {
                    title: 'Реквизиты',
                    data: 'requisites'
                },
                {
                    title: 'Инициалы',
                    data: null,
                    render: function (t) {
                        return `${t.lastName} ${t.firstName}`;
                    }
                },
                {
                    title: 'Комментарий',
                    data: 'comment'
                },
                {
                    title: 'Город',
                    data: 'city'
                },
                {
                    title: 'Функции',
                    data: null,
                    render: function (t) {
                        return `<span class="badge accept" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#41b52a" class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                </svg></span>
                                <span class="badge return" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#b5ac2a" class="bi bi-box-arrow-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z"/>
                                <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                </svg></span>`;
                    }
                }
            ]
        });
    }, [table]);

    useEffect(() => {
        loadDataTable();
    }, [loadDataTable]);

    useEffect(() => {
        $(table.current).off().on('click', 'span.accept', function () {
            props.acceptCheck($(table.current).DataTable().row($(this).parent().parent()).data());
        });

        $(table.current).on('click', 'span.return', function () {
            props.returnCheck($(table.current).DataTable().row($(this).parent().parent()).data());
        });

        setTimeout(() => {
            $(table.current).DataTable().columns.adjust().draw();
        }, 400);
    });

    useEffect(() => {
        if (!ref) return;
        typeof ref === 'function' ? ref(table.current) : (ref.current = table.current);
        return () => typeof ref === 'function' ? ref(null) : (ref.current = null);
    }, [table, ref])

    return (
        <>
            <table className='w-100 display nowrap' ref={table}></table>
        </>
    );
});
