import Checks from 'components/administration/needCheck/Checks';

export default NeedCheck;

function NeedCheck(props) {

    return (
        <>
            <Checks />
        </>
    );
}