import React, { useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";

export default CurrentsTable;

const moment = require("moment");
const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function CurrentsTable({ results, copyAll, refund }) {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: results,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        buttons: {
          dom: {
            button: {
              tag: "button",
              className: "",
            },
          },
          buttons: [
            {
              text: "Скопировать все",
              className: "btn btn-secondary mt-2 mt-sm-auto",
              action: async function (e, dt, node, config) {
                copyAll(dt.data());
              },
            },
          ],
        },
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[0, "asc"]],
        columns: [
          {
            title: "Дата и время",
            data: "dateSold",
            width: 100,
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Аккаунт",
            data: "data",
          },
          {
            title: "Копировать",
            data: null,
            width: 10,
            className: "dt-center",
            render: function (t) {
              return (
                '<span class="links badge" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#2369eb" class="bi bi-clipboard" viewBox="0 0 16 16">' +
                '<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>' +
                '<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>' +
                "</svg></span>"
              );
            },
          },
          {
            title: "Замена",
            data: null,
            width: 10,
            className: "dt-center",
            render: function (t) {
              return (
                '<span class="refund badge" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">' +
                '<path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>' +
                '<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>' +
                "</svg></span>"
              );
            },
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (results) {
        $(table.current).DataTable().rows.add(results).draw(true);
      }
    }
  }, [results, copyAll]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    $(table.current)
      .off()
      .on("click", "span.links", function () {
        let choose = $(table.current)
          .DataTable()
          .row($(this).parent().parent())
          .data();
        navigator.clipboard.writeText(choose.data);
        toast.info("Скопировано в буфер обмена");
      });

    $(table.current)
      .on("click", "span.refund", function () {
        let choose = $(table.current)
          .DataTable()
          .row($(this).parent().parent())
          .data();
        refund(choose);
      });

    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
}
