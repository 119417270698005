import PrivateComponent from "components/shared/PrivateComponent";
import { Role } from "helpers/role";
import Currents from "./currents/Currents";

export default Accounts;

function Accounts(props) {
  return (
    <PrivateComponent>
      <Currents roles={[Role.Manager]} />
    </PrivateComponent>
  );
}
