import React, { useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { ChangeSum } from "./Comparer";

import { fetchWrapper } from "../../../helpers/fetchwrapper";

const moment = require("moment");
const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

export const ManagerTable = React.forwardRef((props, ref) => {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        ajax: function (data, callback, settings) {
          props.getData(moment().subtract(1, "days").format("YYYY-MM-DD")).then(
            (result) => {
              result.forEach(function (current, indexCurrent) {
                result.forEach(function (compare, indexCompare) {
                  if (
                    current.number === compare.number &&
                    current.id !== compare.id
                  ) {
                    compare.color = current.color
                      ? current.color
                      : props.getRandomColor();
                  }
                });
              });

              callback({
                data: result,
              });
            }
          );
        },
        dom: '<"top"f><"clear">lrtp<"bottom"<"row d-flex justify-content-between"<"w-auto"i><"w-auto"B>>>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        buttons: {
          dom: {
            button: {
              tag: "button",
              className: "",
            },
          },
          buttons: [
            {
              text: "Отклонить чек",
              className: "btn btn-danger mt-2 me-0 cancelCheck",
            },
            {
              text: "Ручная сверка",
              className: "btn btn-success mt-2 ms-1 me-0 manualComparer",
            },
            {
              text: "Автоматическая сверка",
              className: "btn btn-success mt-2 ms-1 me-0 autoComparer",
            },
          ],
        },
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[2, "asc"]],
        rowCallback: function (row, data, index) {
          if (data.color) {
            $("td", row).css("background-color", data.color + "4C");
          }
        },
        columns: [
          {
            title: "Менеджер",
            data: null,
            render: function (t) {
              return `${t.user.lastName} ${t.user.firstName}`;
            },
          },
          {
            title: "Добавлен",
            data: "dateCreate",
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Время",
            data: "datePay",
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Номер",
            data: "number",
          },
          {
            title: "Сумма",
            data: "sum",
          },
          {
            title: "Реквизиты",
            data: "requisites",
          },
          {
            title: "Инициалы",
            data: null,
            render: function (t) {
              return `${t.lastName} ${t.firstName}`;
            },
          },
          {
            title: "Доплата",
            data: null,
            render: function (t) {
              return t.type ? "Да" : "Нет";
            },
          },
          {
            title: "Комментарий",
            data: "comment",
          },
          {
            title: "Город",
            data: "city",
          },
          {
            title: "Функции",
            data: null,
            render: function (t) {
              return `<span title="Отправить на проверку" class="badge needCheck" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#62cfd3" class="bi bi-question-diamond-fill" viewBox="0 0 16 16">
                                      <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"/>
                                      </svg></span>`;
            },
          },
        ],
      });

      $($(table.current).DataTable().table().container()).find(".top")
        .append(`<div class="form-group d-flex align-items-center">\
                          <div class="form-group d-flex align-items-center">\
                              <label for="date" style="color: #333;">Дата:</label>\
                              <input type="date" class="form-control" style="border: 1px solid #aaa; border-radius: 3px; margin-left: 3px; width: 140px;" id="dateManagerTable" value="${moment()
                                .subtract(1, "days")
                                .format("YYYY-MM-DD")}"/>\
                          </div>\
                          <div class="btn btn-secondary ms-2" id="changeSumManagerTable">\
                              Изменить сумму\
                          </div>\
                      </div>`);

      $($(table.current).DataTable().table().container())
        .find(".top #dateManagerTable")
        .off()
        .on("change", async function () {
          if ($(this).val()) {
            let data = await props.getData($(this).val()).then((result) => {
              return result;
            });

            data.forEach(function (current, indexCurrent) {
              data.forEach(function (compare, indexCompare) {
                if (
                  current.number === compare.number &&
                  current.id !== compare.id
                ) {
                  compare.color = current.color
                    ? current.color
                    : props.getRandomColor();
                }
              });
            });

            $(table.current).DataTable().clear();
            $(table.current).DataTable().rows.add(data);
            $(table.current).DataTable().draw();
          }
        });

      $($(table.current).DataTable().table().container())
        .find(".top #changeSumManagerTable")
        .off()
        .on("click", async function () {
          let data = $(table.current).DataTable().row(".selected").data();
          if (data) {
            props.setConstructorDialog(ChangeSum(data));
            props.setShowDialog(true);
          } else {
            toast.error("Ничего не выбрано");
          }
        });

      $(table.current)
        .off()
        .on("click", "span.needCheck", function () {
          let choose = $(table.current)
            .DataTable()
            .row($(this).parent().parent())
            .data();
          let data = new FormData();
          data.append("id", choose.id);
          fetchWrapper.post(`api/checks/needCheck`, data, false).then(() => {});
        });

      $(table.current).on("click", "tr", function () {
        $(table.current).find("tr").removeClass("selected");
        $(this).addClass("selected");
      });
    }
  }, [props]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    $($(table.current).DataTable().table().container())
      .find(".bottom button.cancelCheck")
      .off()
      .on("click", function () {
        props.cancelCheck();
      });

    $($(table.current).DataTable().table().container())
      .find(".bottom button.manualComparer")
      .off()
      .on("click", function () {
        props.manualComparer();
      });

    $($(table.current).DataTable().table().container())
      .find(".bottom button.autoComparer")
      .off()
      .on("click", function () {
        props.autoComparer();
      });

    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  useEffect(() => {
    if (!ref) return;
    typeof ref === "function"
      ? ref(table.current)
      : (ref.current = table.current);
    return () => (typeof ref === "function" ? ref(null) : (ref.current = null));
  }, [table, ref]);

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
});
