import React, { useCallback, useEffect, useRef } from "react";

const moment = require("moment");

export default AccountsSoldTable;

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function AccountsSoldTable({ results }) {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: results,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        responsive: true,
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[1, "asc"]],
        columns: [
          {
            title: "",
            data: null,
            orderable: false,
            width: 10,
            render: function () {
              return "";
            },
          },
          {
            title: "Дата создания",
            data: "dateCreate",
            width: 100,
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Аккаунт",
            data: "data",
          },
          {
            title: "Куплен",
            data: null,
            width: 20,
            render: function (c, s, d) {
              return d.status === 2 ? "Да" : "Нет";
            },
          },
          {
            title: "Когда",
            data: null,
            width: 100,
            render: function (c, s, d) {
              return d.status === 2
                ? moment(d.statusDate).format("DD.MM.YYYY HH:mm")
                : "-";
            },
          },
          {
            title: "Кем",
            data: null,
            render: function (c, s, d) {
              return d.status === 2 ? d.userData : "-";
            },
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (results) {
        $(table.current).DataTable().rows.add(results).draw(true);
      }
    }
  }, [results]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
}
