import React from 'react';
import Select from 'react-select'
import chroma from 'chroma-js';

import Widget from 'components/shared/templates/Widget';
import NewsFeedTable from './NewsFeedTable';

import { fetchWrapper } from '../../../helpers/fetchwrapper';
import { Role } from '../../../helpers/role';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const $ = require('jquery');

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
};

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function previewImage(event) {
    $("#previewImage").empty();
    if ($(event.target)[0].files[0]) {
        $("#previewImage").removeClass('d-none');
        $("#previewImage").append("<img style='max-width: 400px; max-height:400px;' />");
        var reader = new FileReader();
        reader.onload = function (e) {
            $("#previewImage img").attr("src", e.target.result);
        }
        reader.readAsDataURL($(event.target)[0].files[0]);
    }
    else {
        if (!$("#previewImage").hasClass('d-none')) {
            $("#previewImage").addClass('d-none');
        }
    }
}

function loadImage(name) {
    if (name) {
        fetchWrapper.getFileShow(`api/news/images/${name}`).then((result) => {
            $("#previewImage").removeClass('d-none');
            $("#previewImage").append("<img style='max-width: 400px; max-height:400px;' />");
            $("#previewImage img").attr("src", result);
        });
    }
}

export const Add = async (ref) => {
    let description = null;
    let supervisors = (await fetchWrapper.get(`api/users/allbyrole/${Role.Supervisor.value}`)).map(
        supervisor => ({
            value: supervisor.id,
            label: 'Команда ' + supervisor.firstName
        }));
    let allManagers = (await fetchWrapper.get(`api/users/allbyrole/${Role.Manager.value}`)).map(
        manager => ({
            value: manager.id,
            supervisor: manager.supervisorId,
            color: getRandomColor(),
            label: manager.firstName
        }));
    let choosedManagers = allManagers;
    return ({
        title: 'Добавление поста',
        body:
            <>
                <div className="form-group">
                    <label htmlFor="title">Название</label>
                    <input type="text" className="form-control" id="title" placeholder="Введите название поста" />
                </div>
                <div className="form-group">
                    <label htmlFor="text">Текст</label>
                    <CKEditor editor={ClassicEditor} id='text' onChange={(event, editor) => { description = editor.getData() }} />
                </div>
                <div id="previewImage" className='d-none d-flex justify-content-center p-2'></div>
                <div className="form-group">
                    <label htmlFor="image">Изображение</label>
                    <input type="file" className="form-control" id="image" onChange={(e) => previewImage(e)}></input>
                </div>
                <div className="form-group">
                    <label htmlFor="video">Ссылка на видео(youtube)</label>
                    <input type="text" className="form-control" id="video" placeholder="Введите ссылку" />
                </div>
                <div className='form-group'>
                    <label htmlFor="groups">Выбор по команде</label>
                    <Select
                        id='groups'
                        options={supervisors}
                        isMulti={false}
                        onChange={(e) => {
                            ref.current.setValue(allManagers.filter(manager => manager.supervisor === e.value));
                        }}
                        placeholder="Выберите команду"
                        maxMenuHeight={150}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="managers">Менеджеры</label>
                    <Select
                        id='managers'
                        options={allManagers}
                        defaultValue={allManagers}
                        isMulti={true}
                        onChange={(e) => choosedManagers = e}
                        placeholder="Выберите менеджеров"
                        maxMenuHeight={150}
                        styles={colourStyles}
                        ref={ref}
                    />
                </div>
            </>,
        saveName: 'Добавить',
        saveFunc: function (body) {
            let data = new FormData();
            data.append('title', body.find('#title').val());
            if (description) {
                data.append('description', description);
            }
            data.append('linkVideo', body.find('#video').val());
            if (body.find("#image").prop('files')[0]) {
                data.append('image', body.find("#image").prop('files')[0]);
            }
            for (let i = 0; i < choosedManagers.length; i++) {
                data.append('users[]', choosedManagers[i].value);
            }

            return fetchWrapper.post('api/news/add', data, false);
        }
    });
}

export const Edit = async (id, ref) => {
    let choosed = await fetchWrapper.get(`api/news/unpublisheds/${id}`);
    let supervisors = (await fetchWrapper.get(`api/users/allbyrole/${Role.Supervisor.value}`)).map(
        supervisor => ({
            value: supervisor.id,
            label: 'Команда ' + supervisor.firstName
        }));
    let allManagers = (await fetchWrapper.get(`api/users/allbyrole/${Role.Manager.value}`)).map(
        manager => ({
            value: manager.id,
            supervisor: manager.supervisorId,
            color: getRandomColor(),
            label: manager.firstName
        }));
    let choosedManagers = allManagers.filter(manager =>
        choosed.users.filter(item => item === manager.value).length > 0);
    let description = choosed.description;
    return ({
        title: 'Изменение поста',
        body:
            <>
                <input type="hidden" id="id" value={id} />
                <div className="form-group">
                    <label htmlFor="title">Название</label>
                    <input type="text" className="form-control" id="title" placeholder="Введите название поста" defaultValue={choosed.title} />
                </div>
                <div className="form-group">
                    <label htmlFor="text">Текст</label>
                    <CKEditor editor={ClassicEditor} id='text' data={description} onChange={(event, editor) => { description = editor.getData() }} />
                </div>
                <div id="previewImage" className='d-none d-flex justify-content-center p-2' onLoad={loadImage(choosed.image)}></div>
                <div className="form-group">
                    <label htmlFor="image">Изображение</label>
                    <input type="file" className="form-control" id="image" onChange={(e) => previewImage(e)}></input>
                </div>
                <div className="form-group">
                    <label htmlFor="video">Ссылка на видео(youtube)</label>
                    <input type="text" className="form-control" id="video" placeholder="Введите ссылку" defaultValue={choosed.linkVideo} />
                </div>
                <div className='form-group'>
                    <label htmlFor="groups">Выбор по команде</label>
                    <Select
                        id='groups'
                        defaultValue={supervisors}
                        options={supervisors}
                        isMulti={false}
                        onChange={(e) => ref.current.setValue(allManagers.filter(manager => manager.supervisor === e.value))}
                        placeholder="Выберите команду"
                        maxMenuHeight={150}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="managers">Менеджеры</label>
                    <Select
                        id='managers'
                        defaultValue={choosedManagers}
                        options={allManagers}
                        isMulti={true}
                        onChange={(e) => choosedManagers = e}
                        placeholder="Выберите менеджера"
                        maxMenuHeight={150}
                        styles={colourStyles}
                        ref={ref}
                    />
                </div>
            </>
        ,
        saveName: 'Сохранить',
        saveFunc: function (body) {
            let data = new FormData();
            data.append('id', body.find('#id').val());
            data.append('title', body.find('#title').val());
            if (description) {
                data.append('description', description);
            }
            data.append('linkVideo', body.find('#video').val());
            if (body.find("#image").prop('files')[0]) {
                data.append('image', body.find("#image").prop('files')[0]);
            }
            for (let i = 0; i < choosedManagers.length; i++) {
                data.append('users[]', choosedManagers[i].value);
            }

            return fetchWrapper.post('api/news/edit', data, false);
        }
    });
}

export default NewsFeed;

function NewsFeed(props) {

    return (
        <>
            <Widget>
                <h4>Новости</h4>
                <NewsFeedTable />
            </Widget>
        </>
    );
}
