import React, { useCallback, useEffect, useState } from 'react';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

import './styles/newsFeed.css'

export default NewsFeed;

function NewsFeed(props) {
    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState(null);

    const preview = useCallback(async (news) => {
        let urlParams = new URLSearchParams(news.linkVideo);
        let body = <>
            <h5>{news.title}</h5>
            <div dangerouslySetInnerHTML={{ __html: news.description }}></div>
            {
                news.image ?
                    await loadImage(news.image) : null
            }
            {
                news.linkVideo ?
                    urlParams.has('https://www.youtube.com/watch?v') ?
                        <iframe width='100%' className='pt-2' src={'https://www.youtube.com/embed/' + urlParams.get('https://www.youtube.com/watch?v')} title='youtube-preview'></iframe>
                        : null
                    : null
            }
        </>

        return body;
    }, []);

    const load = useCallback(() => {
        setLoading(true);
        fetchWrapper.get('api/news/publisheds').then(async (result) => {
            let posts = [];
            for (let i = 0; i < result.length; i++) {
                posts[posts.length] = await preview(result[i]);
            }
            setNews(posts);
            setLoading(false);
        });
    }, [preview]);

    useEffect(() => {
        load();
    }, [load]);

    async function loadImage(name) {
        if (name) {
            return await fetchWrapper.getFileShow(`api/news/images/${name}`).then((result) => {
                return <div className='d-flex justify-content-center'>
                    <img className='post-image' src={result} alt={name}></img>
                </div>
            });
        }

        return null;
    }

    return (
        <>
            {
                loading ?
                    <>
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                    </> :
                    news ?
                        <div className='row posts'>
                            {
                                news.map((e, i) =>
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 post' key={i}>
                                        {e}
                                    </div>
                                )
                            }
                        </div> : <div>Нет новостей</div>
            }
        </>
    );
}
