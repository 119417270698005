import React, { useState, useEffect, useCallback  } from 'react';

import Widget from 'components/shared/templates/Widget';

import RatingHistoryTable from './RatingHistoryTable';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

export default RatingHistory;

function RatingHistory(props) {
    const [ratingHistory, setRatingHistory] = useState(null);

    const getData = useCallback(() => {
        setRatingHistory(null);
        fetchWrapper.get(`api/rating/getRatingHistory`).then((result) => {
            setRatingHistory(result);
        });
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div className='mb-2'>
            <Widget>
                <div className='row justify-content-between'>
                    <div className='d-sm-flex align-items-center col-sm-6'>
                        <div className='d-flex align-items-center'>
                            <h4>История рокеровки</h4>
                        </div>
                    </div>
                </div>
                <RatingHistoryTable data={ratingHistory} />
            </Widget>
        </div>
    );
}