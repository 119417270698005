import React, { useCallback, useEffect, useRef } from "react";

export default SalarySupervisorsTable;

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function SalarySupervisorsTable(props) {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: props.data,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        createdRow: function (row, data, dataIndex) {
          if (data.supervisor === "Общий итог") {
            $("td:eq(1)", row).attr("colspan", 2);
            $("td:eq(1)", row).css("text-align", "center");
            $("td:eq(2)", row).css("display", "none");
          }
        },
        paging: false,
        searching: false,
        scrollY: 300,
        scrollX: true,
        ordering: false,
        columns: [
          {
            title: "Менеджер",
            data: "supervisor",
          },
          {
            title: "Зарплата менеджеров",
            data: "sum",
          },
          {
            title: "Зарплата куратора",
            data: "sumSupervisor",
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (props.data) {
        $(table.current).DataTable().rows.add(props.data).draw(true);
      }
    }
  }, [props.data]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    setTimeout(() => {
      if ($.fn.DataTable.isDataTable(table.current)) {
        $(table.current).DataTable().columns.adjust().draw();
      }
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
}
