import React, { useCallback, useEffect, useState } from 'react';

import Widget from 'components/shared/templates/Widget';
import Select from 'react-select'
import { fetchWrapper } from '../../../helpers/fetchwrapper';
import { Role } from 'helpers/role';

export default SettingsStatistic;

function SettingsStatistic(props) {
    const [loadSettings, setLoadSettings] = useState(false);
    const [loadSave, setLoadSave] = useState(false);
    const [salarySupervisorPercent, setSalarySupervisorPercent] = useState(null);
    const [salaryPrecent, setSalaryPrecent] = useState(null);
    const [priceAccountOLX, setPriceAccountOLX] = useState(null);
    const [priceNoRefundOLX, setPriceNoRefundOLX] = useState(null);
    const [notCalculateSupervisors, setNotCalculateSupervisors] = useState(null);
    const [calculateBonus, setCalculateBonus] = useState(null);
    const [salarySupervisorParentPercent, setSalarySupervisorParentPercent] = useState(null);
    const [supervisors, setSupervisors] = useState([]);
    const [choosedSupervisor, setChoosedSupervisor] = useState(null);

    const getSupervisors = useCallback(() => {
        fetchWrapper.get(`api/users/allbyrole/${Role.Supervisor.value}`).then((result) => {
            setSupervisors(result.map((item) => {
                return {
                    label: item.lastName + ' ' + item.firstName,
                    value: item.id
                }
            }))
        });
    }, []);

    const getSettings = (supervisorId) => {
        setLoadSettings(true);
        fetchWrapper.get(`api/statistic/getUserSalarySettings/${supervisorId}`).then((result) => {
            setSalarySupervisorPercent(result.salarySupervisorPercent);
            setSalaryPrecent(result.salaryPrecent);
            setPriceAccountOLX(result.priceAccountOLX);
            setPriceNoRefundOLX(result.priceNoRefundOLX);
            setNotCalculateSupervisors(result.notCalculateSupervisors);
            setCalculateBonus(result.calculateBonus);
            setSalarySupervisorParentPercent(result.salarySupervisorParentPercent);
            setLoadSettings(false);
        });
    };

    const saveSettings = (supervisorId) => {
        setLoadSave(true);
        fetchWrapper.post(`api/statistic/setUserSalarySettings`, {
            supervisorId: supervisorId,
            salarySupervisorPercent: salarySupervisorPercent,
            salaryPrecent: salaryPrecent,
            priceAccountOLX: priceAccountOLX,
            priceNoRefundOLX: priceNoRefundOLX,
            notCalculateSupervisors: notCalculateSupervisors,
            calculateBonus: calculateBonus,
            salarySupervisorParentPercent: salarySupervisorParentPercent
        }).then((result) => {
            setLoadSave(false);
        });
    };

    useEffect(() => {
        getSupervisors();
    }, [getSupervisors]);

    useEffect(() => {
        if (choosedSupervisor) {
            getSettings(choosedSupervisor.value);
        }
    }, [choosedSupervisor]);

    return (
        <>
            <Widget>
                <h4>Настройка статистики</h4>
                {
                    supervisors ?
                        <Select
                            options={supervisors}
                            isMulti={false}
                            onChange={(e) => {
                                setChoosedSupervisor(e);
                            }}
                            placeholder="Выберите команду"
                            maxMenuHeight={150}
                        /> : null
                }
                {
                    choosedSupervisor ?
                        loadSettings ?
                            <>
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                            </> :
                            <>
                                <div className='row'>
                                    <div className="form-group col-12 col-md-2">
                                        <label htmlFor="salarySupervisorPercent">Доход куратора, %</label>
                                        <input min={1} type="number" className="form-control" id="salarySupervisorPercent" placeholder="Введите процент" defaultValue={salarySupervisorPercent} onChange={(e) => setSalarySupervisorPercent(e.target.value)} />
                                    </div>
                                    <div className="form-group col-12 col-md-2">
                                        <label htmlFor="salaryPrecent">Зарплата менеджера, %</label>
                                        <input min={1} type="number" className="form-control" id="salaryPrecent" placeholder="Введите процент" defaultValue={salaryPrecent} onChange={(e) => setSalaryPrecent(e.target.value)} />
                                    </div>
                                    <div className="form-group col-12 col-md-2">
                                        <label htmlFor="priceAccountOLX">Стоимость акк. OLX, тг</label>
                                        <input min={1} type="number" className="form-control" id="priceAccountOLX" placeholder="Введите стоимость" defaultValue={priceAccountOLX} onChange={(e) => setPriceAccountOLX(e.target.value)} />
                                    </div>
                                    <div className="form-group col-12 col-md-2">
                                        <label htmlFor="priceNoRefundOLX">Стоимость акк. Без замены, тг</label>
                                        <input min={1} type="number" className="form-control" id="priceNoRefundOLX" placeholder="Введите стоимость" defaultValue={priceNoRefundOLX} onChange={(e) => setPriceNoRefundOLX(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="notCalculateSupervisors">Номера чеков 100%</label>
                                        <input type="text" className="form-control" id="notCalculateSupervisors" placeholder="Введите номера через запятую" defaultValue={notCalculateSupervisors} onChange={(e) => setNotCalculateSupervisors(e.target.value)} />
                                    </div>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="calculateBonus">Номера чеков 10%</label>
                                        <input type="text" className="form-control" id="calculateBonus" placeholder="Введите номера через запятую" defaultValue={calculateBonus} onChange={(e) => setCalculateBonus(e.target.value)} />
                                    </div>
                                    <div className="form-group col-12 col-md-2">
                                        <label htmlFor="salarySupervisorParentPercent">Доход с подкураторов, %</label>
                                        <input min={1} type="number" className="form-control" id="salarySupervisorParentPercent" placeholder="Введите процент" defaultValue={salarySupervisorParentPercent} onChange={(e) => setSalarySupervisorParentPercent(e.target.value)} />
                                    </div>
                                </div>
                                <button disabled={loadSave} className='btn btn-success mt-2' onClick={() => saveSettings(choosedSupervisor.value)}>
                                    {loadSave && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Сохранить
                                </button>
                            </>
                        : null
                }
            </Widget>
        </>
    );
}
