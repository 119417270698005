import SettingsStatistic from 'components/administration/statistic/SettingsStatistic';
import SettingsStatisticTotal from 'components/administration/statistic/SettingsStatisticTotal';
import SettingsRating from 'components/administration/rating/SettingsRating';

export default Statistic;

function Statistic(props) {

    return (
        <>
            <SettingsStatistic />
            <div className='mb-3'></div>
            <SettingsStatisticTotal />
            <div className='mb-3'></div>
            <SettingsRating />
        </>
    );
}