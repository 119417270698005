import React, { useState, useRef, useEffect } from "react";
import InputMask from "react-input-mask";

import Widget from "components/shared/templates/Widget";
import LoadsTable from "./LeadsTable";

import { fetchWrapper } from "../../../helpers/fetchwrapper";

const moment = require("moment");
const $ = require("jquery");

export const Add = async () => {
  return {
    title: "Добавление лида",
    body: (
      <>
        <div className="form-group">
          <label htmlFor="phone">Номер телефона</label>
          <InputMask
            className="form-control"
            id="phone"
            mask="+7 999 999 99 99"
            maskChar=" "
          />
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        phone: body.find("#phone").val(),
      };

      return fetchWrapper.post("api/leads/add", data);
    },
  };
};

export default Leads;

function Leads(props) {
  const [loadForming, setLoadForming] = useState(false);
  const [results, setResults] = useState(null);
  const fromDate = useRef();

  const forming = () => {
    setResults(null);
    setLoadForming(true);
    let data = {
      date: $(fromDate.current).val(),
    };
    fetchWrapper.post(`api/leads/getbydayall`, data).then((result) => {
        setResults(result);
      setLoadForming(false);
    });
  };

  useEffect(() => {
    forming();
  }, []);

  return (
    <>
      <Widget>
        <div className="row justify-content-between">
          <div className="d-sm-flex align-items-center col-sm-6">
            <div className="d-flex align-items-center">
              <h4>Лиды</h4>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end col-sm-6">
            <div className="d-flex align-items-center">
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="ms-auto ms-sm-4 mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={fromDate}
                onChange={forming}
              ></input>
            </div>
          </div>
        </div>
        <LoadsTable results={results} updateDataTable={forming} />
      </Widget>
    </>
  );
}
