import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Add, Edit } from './Users';
import Modal from '../../shared/Modal';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

export default UsersTable;

const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

function UsersTable(props) {
    const [show, setShow] = useState(false);
    const [constructor, setConstructor] = useState({});
    const table = useRef();

    const updateDataTable = async () => {
        $(table.current).DataTable().ajax.reload();
    };

    const loadDataTable = useCallback(() => {
        $(table.current).DataTable({
            ajax: function (data, callback, settings) {
                fetchWrapper.get('api/users/all').then((result) => {
                    callback({
                        data: result
                    });
                });
            },
            dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
            language: {
                "emptyTable": "Нет данных",
                "info": "Показано _START_ до _END_ из _TOTAL_",
                "infoEmpty": "Показано 0 до 0 из 0",
                "infoFiltered": "(Выбрано из _MAX_)",
                "zeroRecords": "Не найдено",
                "loadingRecords": "Загрузка...",
                "search": "Поиск:"
            },
            buttons: {
                dom: {
                    button: {
                        tag: 'button',
                        className: ''
                    }
                },
                buttons: [
                    {
                        text: 'Добавить',
                        className: 'btn btn-success',
                        action: async function (e, dt, node, config) {
                            setConstructor(await Add());
                            setShow(true);
                        }
                    }
                ]
            },
            responsive: true,
            paging: false,
            scrollY: 300,
            scrollX: true,
            order: [[1, 'asc']],
            columns: [
                {
                    title: '',
                    data: null,
                    orderable: false,
                    width: 10,
                    render: function () {
                        return '';
                    }
                },
                {
                    title: 'Фамилия',
                    data: 'lastName'
                },
                {
                    title: 'Имя',
                    data: 'firstName'
                },
                {
                    title: 'Отчество',
                    data: 'middleName'
                },
                {
                    title: 'Логин',
                    data: 'username'
                },
                {
                    title: 'Функции',
                    data: null,
                    orderable: false,
                    width: 10,
                    render: function (t) {
                        return `<span class="badge edit" style="cursor: pointer; width: 30px;" data-id="${t.id}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2369eb" class="bi bi-pencil" viewBox="0 0 16 16"><path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" /></svg></span>`;
                    }
                }
            ]
        });

        $(table.current).on('click', 'span.edit', function () {
            edit($(this).data().id);
        })
    }, []);

    useEffect(() => {
        loadDataTable();
    }, [loadDataTable]);

    useEffect(()=>{
        setTimeout(()=>{
            $(table.current).DataTable().columns.adjust().draw();
        }, 400);
    });

    async function edit(id) {
        setConstructor(await Edit(id));
        setShow(true);
    }

    return (
        <>
            <table className='w-100 display nowrap' ref={table}></table>
            {
                show === true ? <Modal
                    hide={() => setShow(false)}
                    title={constructor.title}
                    body={constructor.body}
                    getDataFunc={constructor.getDataFunc}
                    saveName={constructor.saveName}
                    saveFunc={constructor.saveFunc}
                    successFunc={() => {
                        setShow(false);
                        updateDataTable();
                    }} /> : ''
            }
        </>
    );
}
