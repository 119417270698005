import React, { useCallback, useEffect, useState } from "react";
import Widget from "components/shared/templates/Widget";
import { fetchWrapper } from "../../../../helpers/fetchwrapper";

export default UsersCountNoRefunds;

function UsersCountNoRefunds(props) {
  const [data, setData] = useState(null);

  const getData = useCallback(() => {
    fetchWrapper.get(`api/users/allcountnorefund`).then((result) => {
      setData(result);
    });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div className="mt-2"></div>
      <Widget>
        <div className="row mb-2">
          <div className="col-12">
            <h4>Доступно менеджерам аккаунтов на текущий момент</h4>
            {data ? (
              <div style={{ height: 400, overflowY: "auto" }}>
                {data.map((item, index) => {
                  return (
                    <li key={index} roles={item.roles}>
                      {`${item.name} - ${item.count}`}
                    </li>
                  );
                })}
              </div>
            ) : (
              <>
                <span className="spinner-border spinner-border-sm mb-2"></span>
              </>
            )}
          </div>
        </div>
      </Widget>
    </>
  );
}
