import React, { Component } from 'react';
import { history } from '../helpers/history';

export class ErrorBounder extends Component {
    state = {
        error: null,
    };

    static getDerivedStateFromError(error) {
        return { error };
    }

    render() {
        const { error } = this.state;

        if (error) {
            return (
                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                    <div className='d-block'>
                        <h3 className='text-center p-3'>
                            {error.message}
                        </h3>
                        <div className='d-flex justify-content-center'>
                            <button className="btn btn-danger" onClick={history.navigate.goBack}>
                                Назад
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}