import NoRefunds from "components/administration/noRefunds/main/NoRefunds";
import NoRefundsSold from "components/administration/noRefunds/sold/NoRefundsSold";
import NoRefundsLocked from "components/administration/noRefunds/locked/NoRefundsLocked";
import NoRefundsLockedByReason from "components/administration/noRefunds/lockedByReason/NoRefundsLockedByReason";
import UsersCountNoRefunds from "components/administration/noRefunds/usersCount/UsersCountNoRefunds";
import ManualCalculate from "components/administration/noRefunds/manualCalculate/ManualCalculate";

export default Page;

function Page(props) {
  return (
    <>
      <NoRefunds />
      <div className="mb-3"></div>
      <NoRefundsSold />
      <div className="mb-3"></div>
      <NoRefundsLocked />
      <div className="mb-3"></div>
      <NoRefundsLockedByReason />
      <div className="mb-3"></div>
      <UsersCountNoRefunds />
      <div className="mb-3"></div>
      <ManualCalculate />
    </>
  );
}
