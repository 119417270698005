import React, { useCallback, useEffect, useState } from "react";

import Widget from "components/shared/templates/Widget";
import { fetchWrapper } from "../../../helpers/fetchwrapper";

export default Settings;

function Settings(props) {
  const [loadSettings, setLoadSettings] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [price, setPrice] = useState(null);

  const getSettings = useCallback(() => {
    setLoadSettings(true);
    fetchWrapper.get(`api/leads/getSettings`).then((result) => {
      setPrice(result.price);

      setLoadSettings(false);
    });
  }, []);

  const saveSettings = () => {
    setLoadSave(true);
    fetchWrapper
      .post(`api/leads/setSettings`, {
        price: price,
      })
      .then((result) => {
        setLoadSave(false);
      });
  };

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <>
      <Widget>
        <h4>Настройка лидов</h4>
        {loadSettings ? (
          <>
            <span className="spinner-border spinner-border-sm mr-1"></span>
          </>
        ) : (
          <>
            <div className="row">
              <div className="form-group col-12 col-md-4">
                <label htmlFor="price">Цена за лид, тг</label>
                <input
                  min={1}
                  type="number"
                  className="form-control"
                  id="price"
                  placeholder="Введите цену за лид"
                  defaultValue={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <button
              disabled={loadSave}
              className="btn btn-success mt-2"
              onClick={saveSettings}
            >
              {loadSave && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Сохранить
            </button>
          </>
        )}
      </Widget>
    </>
  );
}
