import { Role } from "helpers/role";
import { useSelector } from "react-redux";

export default PrivateComponent;

function PrivateComponent({ children }) {
    const { user: authUser } = useSelector(x => x.auth);
    
    if(children instanceof Array){
        return children.map(c=>c.props ? c.props.roles ? Role.hasRole(c.props.roles, authUser.roles) ? c : null : c : c);
    }
    else{
        return children.props ? children.props.roles ? Role.hasRole(children.props.roles, authUser.roles) ? children : <></> : children : children;
    }
}