import React, { useCallback, useEffect, useRef } from "react";

import "./styles/userSalaryTable.css";

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.buttons = require("datatables.net-buttons");

export const UserSalaryTable = React.forwardRef((props, ref) => {
  const table = useRef();

  const getColumns = useCallback(() => {
    let columns = [];
    addColumn(
      columns,
      "№",
      null,
      "text-center",
      function (data, type, row, meta) {
        if (data.counter) {
          return data.counter;
        } else {
          data.counter = meta.row + meta.settings._iDisplayStart + 1;
          return data.counter;
        }
      }
    );
    addColumn(
      columns,
      "%",
      "salarySupervisorParent",
      "bg-lightpink text-center",
      function (t) {
        return t;
      }
    );
    addColumn(
      columns,
      "Доплата куратора",
      "salaryAddingSupervisor",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return props.supervisor.statistics.reduce(
            (t, a) => t + a.salaryAddingSupervisor,
            0
          );
        } else {
          return (
            `<input type="number" name="salaryAddingSupervisor" value="${t}"></input><div class="a-note-container">` +
            '<div class="a-note"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
            '<div class="a-note-content"><textarea name="note" placeholder="Оставить заметку" data-name="salaryAddingSupervisor"></textarea></div></div></div>'
          );
        }
      }
    );
    addColumn(
      columns,
      "Доход куратора",
      "salarySupervisor",
      "bg-lightpink text-center",
      function (t) {
        return t;
      }
    );
    addColumn(columns, "Имя", "name", "bg-lightblue", function (t) {
      if (t === "total") {
        return "";
      } else {
        return (
          t +
          '<div class="a-note-container">' +
          '<div class="a-note a-note-readonly checks"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
          '<div class="a-note-content low"></div><div class="a-note-content high"></div></div></div>'
        );
      }
    });
    addColumn(
      columns,
      "Зарплата менеджера",
      "salary",
      "bg-lightpink text-center",
      function (t) {
        return t;
      }
    );
    addColumn(
      columns,
      "Кол-во чеков",
      "countChecks",
      "bg-lightpink text-center",
      function (t) {
        return t;
      }
    );

    if (props.supervisor.columns) {
      props.supervisor.columns.forEach((el, id, arr) => {
        addColumn(
          columns,
          el + " чек",
          null,
          "bg-lightgreen text-center",
          function (t) {
            if (t.nominals[el]) {
              return t.nominals[el];
            } else {
              return 0;
            }
          }
        );
      });
    }

    addColumn(
      columns,
      "Налог на Kaspi RED",
      "taxKaspiRed",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return props.supervisor.statistics.reduce(
            (t, a) => t + a.taxKaspiRed,
            0
          );
        } else {
          return (
            `<input type="number" name="taxKaspiRed" value="${t}"></input><div class="a-note-container">` +
            '<div class="a-note"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
            '<div class="a-note-content"><textarea name="note" placeholder="Оставить заметку" data-name="taxKaspiRed"></textarea></div></div></div>'
          );
        }
      }
    );
    addColumn(
      columns,
      "Количество акк-ов",
      "countAccountsOLX",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return props.supervisor.statistics.reduce(
            (t, a) => t + a.countAccountsOLX,
            0
          );
        } else {
          return t;
        }
      }
    );
    addColumn(
      columns,
      "Количество без замены",
      "countNoRefundsOLX",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return props.supervisor.statistics.reduce(
            (t, a) => t + a.countNoRefundsOLX,
            0
          );
        } else {
          return t;
        }
      }
    );
    addColumn(
      columns,
      "Кол-во лидов",
      "countLeads",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return props.supervisor.statistics.reduce(
            (t, a) => t + a.countLeads,
            0
          );
        } else {
          return t;
        }
      }
    );
    addColumn(
      columns,
      "Текущий минус",
      "currentMinus",
      "bg-lightpink text-center",
      function (t, e, d) {
        if (t === "total") {
          return props.supervisor.statistics.reduce(
            (t, a) =>
              t +
              a.sumLeads +
              a.countAccountsOLX * props.supervisor.settings.priceAccountOLX +
              a.countNoRefundsOLX * props.supervisor.settings.priceNoRefundOLX +
              a.taxKaspiRed -
              a.debt,
            0
          );
        } else {
          return (
            d.sumLeads +
            d.countAccountsOLX * props.supervisor.settings.priceAccountOLX +
            d.countNoRefundsOLX * props.supervisor.settings.priceNoRefundOLX +
            d.taxKaspiRed -
            d.debt
          );
        }
      }
    );
    addColumn(
      columns,
      "Действие с долгом",
      "debtAction",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return "";
        } else {
          return `<span class="debt badge" style="cursor: pointer; width: 30px;" title="Объединить долг">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"/>
            </svg>
          </span>
          <span class="credit badge" style="cursor: pointer; width: 30px;" title="Списать долг">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-arrow-bar-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"/>
            </svg>
          </span>`;
        }
      }
    );
    addColumn(
      columns,
      "Долг",
      "totalDebt",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return props.supervisor.statistics.reduce(
            (t, a) => t + a.totalDebt,
            0
          );
        } else {
          return t;
        }
      }
    );
    addColumn(
      columns,
      "Бонусы",
      "bonus",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return "";
        } else {
          return (
            `<input type="number" name="bonus" value="${t}"></input><div class="a-note-container">` +
            '<div class="a-note"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
            '<div class="a-note-content"><textarea name="note" placeholder="Оставить заметку" data-name="bonus"></textarea></div></div></div>'
          );
        }
      }
    );
    addColumn(
      columns,
      "Штраф",
      "payFine",
      "bg-lightpink text-center",
      function (t) {
        if (t === "total") {
          return "";
        } else {
          return (
            `<input type="number" name="payFine" value="${t}"></input><div class="a-note-container">` +
            '<div class="a-note"><div class="a-note-header"><button class="close" type="button">&times;</button></div>' +
            '<div class="a-note-content"><textarea name="note" placeholder="Оставить заметку" data-name="payFine"></textarea></div></div></div>'
          );
        }
      }
    );

    return columns;
  }, [props]);

  const addColumn = (columns, title, data, className, render) => {
    columns[columns.length] = {
      title: title,
      data: data,
      className: className,
      render: render,
    };
  };

  const showTotals = (nominals) => {
    if ($.fn.DataTable.isDataTable(table.current)) {
      let dataTable = $(table.current).DataTable();
      let info = dataTable.data();

      let infoWithoutTotals = info.filter((e) => {
        return e.counter !== "<b>Итог:</b>";
      });

      let data = {
        counter: "<b>Итог:</b>",
        salaryAddingSupervisor: "total",
        salarySupervisorParent: infoWithoutTotals.reduce(
          (t, a) => t + a.salarySupervisorParent,
          0
        ),
        salarySupervisor: infoWithoutTotals.reduce(
          (t, a) => t + a.salarySupervisor,
          0
        ),
        name: "total",
        salary: infoWithoutTotals.reduce((t, a) => t + a.salary, 0),
        countChecks: infoWithoutTotals.reduce((t, a) => t + a.countChecks, 0),
        taxKaspiRed: "total",
        countAccountsOLX: "total",
        countNoRefundsOLX: "total",
        countLeads: infoWithoutTotals.reduce((t, a) => t + a.countLeads, 0),
        currentMinus: "total",
        debtAction: "total",
        totalDebt: "total",
        bonus: "total",
        payFine: "total",
      };

      if (nominals) {
        data.nominals = {};
        nominals.forEach((el, id, arr) => {
          data.nominals[el] = infoWithoutTotals.reduce(
            (t, a) => t + (a.nominals[el] ? a.nominals[el] : 0),
            0
          );
        });
      }

      if (infoWithoutTotals.length !== info.length) {
        dataTable
          .row(info.length - 1)
          .data(data)
          .draw(false);
      } else {
        dataTable.row.add(data).draw(false);
      }
    }
  };

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: props.supervisor.statistics,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        info: false,
        autoWidth: true,
        searching: false,
        paging: false,
        scrollY: 300,
        scrollX: true,
        ordering: false,
        columns: getColumns(),
        initComplete: function (settings, json) {
          setTimeout(() => {
            showTotals(props.supervisor.columns);
          }, 500);

          $(table.current).off();

          $(table.current).on("click", ".close", function () {
            $(this).parent().parent().parent().removeClass("is-active");
          });

          $(table.current).on(
            "change",
            'input[name="salaryAddingSupervisor"]',
            function () {
              let row = $(table.current)
                .DataTable()
                .row($(this).parent().parent());
              let data = row.data();
              data.salarySupervisor =
                data.salarySupervisor - data.salaryAddingSupervisor;
              let temp = parseInt($(this).val());
              if (temp !== null && temp !== undefined) {
                data.salaryAddingSupervisor = temp;
              }

              data.salarySupervisor =
                data.salarySupervisor + data.salaryAddingSupervisor;
              row.data(data).draw(false);
              showTotals(props.supervisor.columns);
              props.save(data);
            }
          );

          $(table.current).on(
            "dblclick",
            'input[name="salaryAddingSupervisor"]',
            function () {
              let row = $(table.current)
                .DataTable()
                .row($(this).parent().parent());
              let data = row.data();
              $(this)
                .parent()
                .find("div.a-note-container")
                .addClass("is-active");
              $(this)
                .parent()
                .find("div.a-note-container textarea")
                .val(data.salaryAddingSupervisorComment);
            }
          );

          $(table.current).on(
            "change",
            'input[name="taxKaspiRed"]',
            function () {
              let row = $(table.current)
                .DataTable()
                .row($(this).parent().parent());
              let data = row.data();
              data.salary = data.salary + data.taxKaspiRed;
              let temp = parseInt($(this).val());
              if (temp !== null && temp !== undefined) {
                data.taxKaspiRed = temp;
              }
              data.salary = data.salary - data.taxKaspiRed;
              row.data(data).draw(false);
              showTotals(props.supervisor.columns);
              props.save(data);
            }
          );

          $(table.current).on("dblclick", "tr td:nth-child(5)", function () {
            let row = $(table.current).DataTable().row($(this).parent());
            let data = row.data();
            $(this).find("div.a-note-container").addClass("is-active");
            $(this).find("div.a-note-content.low").text(data.numericChecksLow);
            $(this)
              .find("div.a-note-content.high")
              .text(data.numericChecksHigh);
          });

          $(table.current).on(
            "dblclick",
            'input[name="taxKaspiRed"]',
            function () {
              let row = $(table.current)
                .DataTable()
                .row($(this).parent().parent());
              let data = row.data();
              $(this)
                .parent()
                .find("div.a-note-container")
                .addClass("is-active");
              $(this)
                .parent()
                .find("div.a-note-container textarea")
                .val(data.taxKaspiRedComment);
            }
          );

          $(table.current).on("change", 'input[name="bonus"]', function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent());
            let data = row.data();
            data.salary = data.salary - data.bonus;
            let temp = parseInt($(this).val());
            if (temp !== null && temp !== undefined) {
              data.bonus = temp;
            }
            data.salary = data.salary + data.bonus;
            row.data(data).draw(false);
            showTotals(props.supervisor.columns);
            props.save(data);
          });

          $(table.current).on("dblclick", 'input[name="bonus"]', function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent());
            let data = row.data();
            $(this).parent().find("div.a-note-container").addClass("is-active");
            $(this)
              .parent()
              .find("div.a-note-container textarea")
              .val(data.bonusComment);
          });

          $(table.current).on("change", 'input[name="payFine"]', function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent());
            let data = row.data();
            data.salary = data.salary + data.payFine;
            let temp = parseInt($(this).val());
            if (temp !== null && temp !== undefined) {
              data.payFine = temp;
            }
            data.salary = data.salary - data.payFine;
            row.data(data).draw(false);
            showTotals(props.supervisor.columns);
            props.save(data);
          });

          $(table.current).on("dblclick", 'input[name="payFine"]', function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent());
            let data = row.data();
            $(this).parent().find("div.a-note-container").addClass("is-active");
            $(this)
              .parent()
              .find("div.a-note-container textarea")
              .val(data.payFineComment);
          });

          $(table.current).on("click", "span.debt", function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent());
            let data = row.data();
            data.totalDebt -= data.debt;
            data.debt =
              data.sumLeads +
              data.countAccountsOLX * props.supervisor.settings.priceAccountOLX +
              data.taxKaspiRed;
            data.totalDebt += data.debt;
            data.salary += data.debt;
            row.data(data).draw(false);
            showTotals(props.supervisor.columns);
            props.save(data);
          });

          $(table.current).on("click", "span.credit", function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent());
            let data = row.data();
            data.salary -= data.totalDebt;
            data.credit = data.totalDebt;
            data.totalDebt = 0;
            row.data(data).draw(false);
            showTotals(props.supervisor.columns);
            props.save(data);
          });

          $(table.current).on("change", "td textarea", function () {
            let row = $(table.current)
              .DataTable()
              .row($(this).parent().parent().parent().parent());
            let data = row.data();

            switch ($(this).data().name) {
              case "salaryAddingSupervisor":
                data.salaryAddingSupervisorComment = $(this).val();
                break;
              case "taxKaspiRed":
                data.taxKaspiRedComment = $(this).val();
                break;
              case "bonus":
                data.bonusComment = $(this).val();
                break;
              case "payFine":
                data.payFineComment = $(this).val();
                break;
              default:
                break;
            }

            row.data(data).draw(false);
            props.save(data);
          });
        },
      });
    }
  }, [props, getColumns]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  useEffect(() => {
    typeof ref === "function"
      ? ref(props.index, table.current)
      : (ref.current = table.current);
    return () =>
      typeof ref === "function" ? ref(props.index, null) : (ref.current = null);
  }, [table, ref, props.index]);

  return (
    <div className="userSalaryTable">
      <h5>{props.supervisor.supervisorName}</h5>
      <table className="w-100 display nowrap" ref={table}></table>
    </div>
  );
});
