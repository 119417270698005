import PrivateComponent from "components/shared/PrivateComponent";
import ChecksCities from "components/statistic/checksCities/ChecksCities";
import ChecksManagers from "components/statistic/checksManagers/ChecksManagers";
import Total from "components/statistic/total/Total";
import { Role } from "helpers/role";

export default Main;

function Main(props) {
    return (
        <PrivateComponent>
            <ChecksCities roles={[Role.Accountant, Role.Admin]} />
            <ChecksManagers roles={[Role.Accountant, Role.Admin]} />
            <Total roles={[Role.Admin]} />
        </PrivateComponent>
    );
}