export const Role = {
    Admin: {value: 1, label: 'Админ'},
    Accountant: {value: 2, label: 'Бухгалтер'},
    Supervisor: {value: 3, label: 'Куратор'},
    Manager: {value: 4, label: 'Менеджер'},
    All:[
        {value: 1, label: 'Админ'},
        {value: 2, label: 'Бухгалтер'},
        {value: 3, label: 'Куратор'},
        {value: 4, label: 'Менеджер'}
    ],
    hasRole: checkHasRole
}

function checkHasRole(need, has) {
    const [long, short] = need.length > has.length ? [need.map(v=>v.value), has.map(v=>v.id)] : [has.map(v=>v.id), need.map(v=>v.value)];
    short.sort();
    const shortLength = short.length;

    const binSearch = needle => {
        let start = 0, finish = shortLength - 1;
        while (start <= finish) {
            const center = Math.floor((start + finish) / 2);
            if (short[center] < needle) start = center + 1;
            else if (short[center] > needle) finish = center - 1;
            else return true;
        }
        return false;
    }

    const result = [];
    for (let i = 0, length = long.length; i < length; i++)
        if (binSearch(long[i])) result.push(long[i]);

    if (result.length > 0) {
        return true;
    }
    else {
        return false;
    }
}