import React, { useRef, useState } from "react";

import Widget from "components/shared/templates/Widget";

import { UserSalaryTable } from "./UserSalaryTable";

import { fetchWrapper } from "../../../helpers/fetchwrapper";

import "./styles/userSalary.css";

const moment = require("moment");
const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

export default UserSalary;

function UserSalary(props) {
  const [loadForming, setLoadForming] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [loadDownload, setLoadDownload] = useState(false);
  const [userSalary, setUserSalary] = useState(null);
  const [tables, setTables] = useState([]);
  const onDate = useRef();

  const formingUserSalary = () => {
    setLoadForming(true);
    setTables([]);
    setUserSalary(null);
    fetchWrapper
      .get(`api/statistic/getUserSalary?date=${$(onDate.current).val()}`)
      .then((result) => {
        fetchWrapper
          .get(
            `api/statistic/getUserSalaryColumns?date=${$(onDate.current).val()}`
          )
          .then(async (columns) => {
            for (let i = 0; i < result.length; i++) {
              var settings = await fetchWrapper.get(
                `api/statistic/getUserSalarySettings/${result[i].supervisorId}`
              );
              result[i].settings = settings;

              result[i].columns = columns.find((item, index, array) => {
                if (item.supervisorId === result[i].supervisorId) {
                  return true;
                } else {
                  return false;
                }
              });

              if (!result[i].columns) {
                result[i].columns = [];
              } else {
                result[i].columns = result[i].columns.names;
              }
            }
            setUserSalary(result);
            setLoadForming(false);
          });
      });
  };

  const saveUserSalary = (row) => {
    setLoadSave(true);
    let date = $(onDate.current).val();

    let data = {
      date: date,
      userId: row.userId,
      salaryAddingSupervisor: row.salaryAddingSupervisor,
      salaryAddingSupervisorComment: row.salaryAddingSupervisorComment,
      taxKaspiRed: row.taxKaspiRed,
      taxKaspiRedComment: row.taxKaspiRedComment,
      bonus: row.bonus,
      bonusComment: row.bonusComment,
      payFine: row.payFine,
      payFineComment: row.payFineComment,
    };

    fetchWrapper.post(`api/statistic/saveUserSalaryAddition`, data).then(() => {
      let total = {
        date: date,
        userId: row.userId,
        countChecks: row.countChecks,
        salary: row.salary,
        salarySupervisor: row.salarySupervisor,
        salarySupervisorParent: row.salarySupervisorParent,
        debt: row.debt,
        credit: row.credit,
      };
      fetchWrapper.post(`api/statistic/saveUserSalaryTotal`, total).then(() => {
        setLoadSave(false);
      });
    });
  };

  const downloadUserSalary = () => {
    setLoadDownload(true);
    let date = $(onDate.current).val();
    fetchWrapper
      .get(
        `api/statistic/downloadUserSalary?date=${date}`,
        null,
        true,
        true,
        "Расчет зарплаты от " + date + ".xlsx"
      )
      .then(() => {
        setLoadDownload(false);
      });
  };

  const getRefForTable = (i, ref) => {
    tables[i] = ref;
  };

  return (
    <div className="mb-2">
      <Widget>
        <div className="row justify-content-between">
          <div className="d-flex align-items-center col-sm-6">
            <h4>Расчёт зарплаты</h4>
            <input
              disabled={loadForming || loadSave || loadDownload}
              id="onDate"
              type="date"
              className="ms-4 mt-2 mb-2 form-control"
              style={{ maxWidth: 150 }}
              defaultValue={moment().format("YYYY-MM-DD")}
              ref={onDate}
            ></input>
          </div>
          <div className="d-flex align-items-center justify-content-end col-sm-6">
            {userSalary ? (
              <>
                {loadSave && (
                  <span>
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                    Сохранение
                  </span>
                )}
              </>
            ) : (
              <></>
            )}
            <button
              disabled={loadForming || loadSave || loadDownload}
              className="btn btn-primary ms-2"
              onClick={formingUserSalary}
            >
              {loadForming && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Сформировать
            </button>
            {userSalary ? (
              <>
                <button
                  disabled={loadDownload}
                  className="btn btn-dark ms-2"
                  onClick={downloadUserSalary}
                >
                  {loadDownload && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Скачать
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {userSalary ? (
          userSalary.map((element, i) => (
            <div className="mb-2" key={i}>
              <UserSalaryTable
                supervisor={element}
                save={saveUserSalary}
                ref={getRefForTable}
                index={i}
              />
            </div>
          ))
        ) : (
          <></>
        )}
      </Widget>
    </div>
  );
}
