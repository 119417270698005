import Reports from "components/administration/totals/reports/Totals";
import Calculate from "components/administration/totals/calculate/Totals";

export default Page;

function Page(props) {
  return (
    <>
      <Reports />
      <div className="mb-3"></div>
      <Calculate />
    </>
  );
}
