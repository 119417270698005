import React, { useState } from 'react';

import './styles/modal.css';

const $ = require('jquery');

export default Modal;

function Modal(props) {
  const modalStyle = {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,0.4)'
  }

  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);

  async function onSave(){
    setLoad(true);
    setError('');
    props.saveFunc($('.modal-body')).then((response) => {
      props.successFunc(response);
    }).catch((response) => {
      setError(response);
      setLoad(false);
    });
  }

  return (
    <>
      <div className='modal fade show' aria-hidden='true' style={modalStyle} onClick={props.hide}>
        <div className='modal-dialog-right' onClick={(e) => e.stopPropagation()}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{props.title}</h5>
              <button disabled={load} type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Закрыть' onClick={props.hide}></button>
            </div>
            <div className='modal-body'>
              <div className={error ? 'alert alert-danger' : 'alert alert-danger d-none'} role='alert'>
                {error}
              </div>
              {props.body}
            </div>
            <div className='modal-footer'>
              <button disabled={load} type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={props.hide}>Закрыть</button>
              <button disabled={load} type='button' className='btn btn-primary' onClick={onSave}>
                {load && <span className="spinner-border spinner-border-sm mr-1"></span>}
                {props.saveName}
                </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}