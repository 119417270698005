import React, { useCallback, useEffect, useRef } from "react";

export default ChecksTable;

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function ChecksTable(props) {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: props.data,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        paging: false,
        searching: false,
        scrollY: 300,
        scrollX: true,
        ordering: false,
        columns: [
          {
            title: "Сумма чека",
            data: "nominal",
          },
          {
            title: "Остаток",
            data: "percent",
          },
          {
            title: "Кол-во",
            data: "count",
          },
          {
            title: "Бонусные",
            data: "bonusCount",
          },
          {
            title: "Общая сумма",
            data: "sum",
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (props.data) {
        $(table.current).DataTable().rows.add(props.data).draw(true);
      }
    }
  }, [props.data]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    setTimeout(() => {
      if ($.fn.DataTable.isDataTable(table.current)) {
        $(table.current).DataTable().columns.adjust().draw();
      }
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
}
