import React from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ErrorBounder } from "./errors/ErrorBounder";
import PrivateRoute from "./components/shared/PrivateRoute";
import Layout from "./components/shared/Layout";

import { News } from "./components/news/News";
import Checks from "components/checks/Checks";
import Leads from "components/leads/Leads";
import Accounts from "components/accounts/Accounts";
import NoRefunds from "components/noRefunds/NoRefunds";
import Materials from "components/materials/Materials";

import Settings from "pages/administration/settings/Settings";
import NeedCheck from "pages/administration/needcheck/NeedCheck";
import Statistic from "pages/administration/statistic/Statistic";
import AdminLeads from "pages/administration/leads/Leads";
import AdminAccounts from "pages/administration/accounts/Accounts";
import AdminNoRefunds from "pages/administration/noRefunds/NoRefunds";
import AdminLinks from "pages/administration/links/Page";
import AdminTotals from "pages/administration/totals/Page";

import Main from "pages/statistic/main/Main";
import Salary from "pages/statistic/salary/Salary";

import Login from "./login/Login";
import ForgetPassword from "./login/ForgetPassword";

import { history } from "./helpers/history";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./components/shared/styles/datatables.min.css";

import "react-toastify/dist/ReactToastify.css";

export { App };

const moment = require("moment");
const $ = require("jquery");

$.fn.dataTableExt.oSort["ru_date-asc"] = function (a, b) {
  var x = moment(a, "DD.MM.YYYY HH:mm").format("X"),
    y = moment(b, "DD.MM.YYYY HH:mm").format("X");
  return x < y ? -1 : x > y ? 1 : 0;
};

$.fn.dataTableExt.oSort["ru_date-desc"] = function (a, b) {
  var x = moment(a, "DD.MM.YYYY HH:mm").format("X"),
    y = moment(b, "DD.MM.YYYY HH:mm").format("X");
  return x < y ? 1 : x > y ? -1 : 0;
};

function App() {
  history.navigate = useHistory();
  history.location = useLocation();

  return (
    <ErrorBounder>
      <PrivateRoute>
        <Layout>
          <Switch>
            <Route exact path="/" component={News} />
            <Route path="/checks" component={Checks} />
            <Route path="/leads" component={Leads} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/norefunds" component={NoRefunds} />
            <Route path="/statistic/main" component={Main} />
            <Route path="/statistic/salary" component={Salary} />
            <Route path="/materials" component={Materials} />
            <Route path="/administration/settings" component={Settings} />
            <Route path="/administration/statistic" component={Statistic} />
            <Route path="/administration/needcheck" component={NeedCheck} />
            <Route path="/administration/leads" component={AdminLeads} />
            <Route path="/administration/accounts" component={AdminAccounts} />
            <Route path="/administration/norefunds" component={AdminNoRefunds} />
            <Route path="/administration/links" component={AdminLinks} />
            <Route path="/administration/totals" component={AdminTotals} />
          </Switch>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Layout>
      </PrivateRoute>
      <Route path="/login" component={Login} />
      <Route path="/forget-password" component={ForgetPassword} />
    </ErrorBounder>
  );
}
