import React, { useState, useRef, useEffect } from "react";

import Widget from "components/shared/templates/Widget";
import AccountsLockedTable from "./NoRefundsLockedTable";

import { fetchWrapper } from "../../../../helpers/fetchwrapper";

const moment = require("moment");
const $ = require("jquery");

export default NoRefundsLocked;

function NoRefundsLocked(props) {
  const [loadForming, setLoadForming] = useState(false);
  const [results, setResults] = useState(null);
  const fromDate = useRef();
  const toDate = useRef();

  const forming = () => {
    setResults(null);
    setLoadForming(true);
    let data = {
      from: $(fromDate.current).val(),
      to: $(toDate.current).val(),
    };
    fetchWrapper.post(`api/norefunds/getbydayalllocked`, data).then((result) => {
      setResults(result);
      setLoadForming(false);
    });
  };

  useEffect(() => {
    forming();
  }, []);

  return (
    <>
      <Widget>
        <div className="d-sm-flex flex-wrap justify-content-between">
          <div className="d-sm-flex align-items-center">
            <div className="d-flex align-items-center">
              <h4>Выведенные из оборота аккаунты</h4>
            </div>
          </div>
          <div className="d-sm-flex flex-wrap align-items-center justify-content-end w-100">
            <div className="ms-auto d-flex align-items-center justify-content-end gap-2">
              <label>С</label>
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={fromDate}
              ></input>
            </div>
            <div className="ms-auto ms-sm-4 d-flex align-items-center justify-content-end gap-2">
              <label>По</label>
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().format("YYYY-MM-DD")}
                ref={toDate}
              ></input>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary ms-auto ms-sm-4"
                onClick={forming}
              >
                Показать
              </button>
            </div>
          </div>
        </div>
        <AccountsLockedTable results={results} />
      </Widget>
    </>
  );
}
