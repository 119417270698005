import React, { useEffect, useState } from "react";
import Button from "./Button";
import Keypad from "./Keypad";

import styles from "./styles/calculator.module.css";

function Calculator({ onChange }) {
  const [formula, setFormula] = useState("");
  const [result, setResult] = useState("");

  const calculate = (data) => {
    try {
      /* eslint no-eval: 0 */
      const result = eval(data);
      if (!result) {
        setResult(data);
      } else {
        setResult(result);
      }
    } catch (e) {
      setResult("0");
    }
  };

  useEffect(() => {
    calculate(formula);
  }, [formula]);

  useEffect(() => {
    onChange({ formula, result });
  }, [formula, result, onChange]);

  const handleClick = (e) => {
    const value = e.target.getAttribute("data-value");
    switch (value) {
      case "clear":
        setFormula("");
        break;
      default:
        setFormula((p) => p + value);
    }
  };

  return (
    <div className={styles.calculator}>
      <Keypad>
        <Button onClick={handleClick} label="C" value="clear" />
        <Button onClick={handleClick} label="7" value="7" />
        <Button onClick={handleClick} label="4" value="4" />
        <Button onClick={handleClick} label="1" value="1" />
        <Button onClick={handleClick} label="0" value="0" />

        <Button onClick={handleClick} label="/" value="/" />
        <Button onClick={handleClick} label="8" value="8" />
        <Button onClick={handleClick} label="5" value="5" />
        <Button onClick={handleClick} label="2" value="2" />
        <Button onClick={handleClick} label="." value="." />

        <Button onClick={handleClick} label="x" value="*" />
        <Button onClick={handleClick} label="9" value="9" />
        <Button onClick={handleClick} label="6" value="6" />
        <Button onClick={handleClick} label="3" value="3" />
        <Button onClick={handleClick} label="" value="null" />

        <Button onClick={handleClick} label="-" size="2" value="-" />
        <Button onClick={handleClick} label="+" size="2" value="+" />
      </Keypad>
    </div>
  );
}

export default Calculator;
