import React, { useRef, useState } from 'react';

import Widget from 'components/shared/templates/Widget';

import ChecksCitiesTable from './ChecksCitiesTable';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

const moment = require('moment');
const $ = require('jquery');
$.DataTable = require('datatables.net');
$.DataTable.rowReorder = require('datatables.net-rowreorder');
$.DataTable.responsive = require('datatables.net-responsive');
$.DataTable.buttons = require('datatables.net-buttons');

export default ChecksCities;

function ChecksCities(props) {
    const [loadForming, setLoadForming] = useState(false);
    const [countChecksCities, setCountChecksCities] = useState(null);
    const fromDate = useRef();
    const toDate = useRef();

    const forming = () => {
        setCountChecksCities(null);
        setLoadForming(true);
        fetchWrapper.post(`api/statistic/getCountChecksCities?from=${$(fromDate.current).val()}&to=${$(toDate.current).val()}`).then((result) => {
            setCountChecksCities(result);
            setLoadForming(false);
        });
    };

    return (
        <div className='mb-2'>
            <Widget>
                <div className='row justify-content-between'>
                    <div className='d-sm-flex align-items-center col-sm-6'>
                        <div className='d-flex align-items-center'>
                            <h4>Кол-во чеков по городам</h4>
                        </div>
                        <div className='d-flex align-items-center'>
                            <input disabled={loadForming} id='fromDate' type='date' className='ms-auto ms-sm-4 mt-2 mb-2 form-control' style={{ maxWidth: 150 }} defaultValue={moment().format('YYYY-MM-DD')} ref={fromDate}></input>
                            <input disabled={loadForming} id='toDate' type='date' className='ms-2 me-auto mt-2 mb-2 form-control' style={{ maxWidth: 150 }} defaultValue={moment().format('YYYY-MM-DD')} ref={toDate}></input>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end col-sm-6'>
                        <button disabled={loadForming} className='btn btn-primary ms-2' onClick={forming}>
                            {loadForming && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Сформировать
                        </button>
                    </div>
                </div>
                <ChecksCitiesTable data={countChecksCities} />
            </Widget>
        </div>
    );
}