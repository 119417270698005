import React, { useCallback, useEffect, useRef, useState } from "react";
import ModalAlert from "../../shared/ModalAlert";
import { toast } from "react-toastify";

import { fetchWrapper } from "../../../helpers/fetchwrapper";

export default CurrentsTable;

const moment = require("moment");
const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function CurrentsTable({ results, updateDataTable }) {
  const [showAlert, setShowAlert] = useState(false);
  const [lead, setLead] = useState(null);
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: results,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        buttons: {
          dom: {
            button: {
              tag: "button",
              className: "",
            },
          },
          buttons: [
            {
              text: "Купить лид",
              className: "btn btn-success",
              action: function (e, dt, node, config) {
                fetchWrapper
                  .post(`api/leads/sold`)
                  .then((result) => {
                    setLead(result);
                    setShowAlert(true);
                  })
                  .catch((error) => toast.error(error));
              },
            },
          ],
        },
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[1, "asc"]],
        columns: [
          {
            title: "Дата и время",
            data: "dateSold",
            width: 100,
            render: function (t) {
              return moment(t).format("MM.DD.YYYY HH:mm");
            },
          },
          {
            title: "Номер",
            data: "phone",
          },
          {
            title: "Цена покупки",
            data: "soldPrice",
            render: function (t) {
              return `${t} тг`;
            },
          },
          {
            title: "Копировать",
            data: null,
            width: 10,
            className: "dt-center",
            render: function (t) {
              return (
                '<span class="links badge" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2369eb" class="bi bi-clipboard" viewBox="0 0 16 16">' +
                '<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>' +
                '<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>' +
                "</svg></span>"
              );
            },
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (results) {
        $(table.current).DataTable().rows.add(results).draw(true);
      }
    }
  }, [results]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    $(table.current)
      .off()
      .on("click", "span.links", function () {
        setLead(
          $(table.current).DataTable().row($(this).parent().parent()).data()
        );
        setShowAlert(true);
      });

    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
      {showAlert ? (
        <ModalAlert
          hide={() => {
            setShowAlert(false);
            updateDataTable();
          }}
          title={"Новый лид"}
          body={
            <div className="row justify-content-center mt-3 mb-3">
              <div className="col-12 text-center">
                Для звонка на данный номер, нажмите на него
              </div>
              <a
                href={`tel:${lead.phone}`}
                className="col-12 text-center h4"
                target="_blank"
                rel="noreferrer"
              >
                {lead.phone}
              </a>
            </div>
          }
        />
      ) : (
        ""
      )}
    </>
  );
}
