import Accounts from "components/administration/accounts/main/Accounts";
import AccountsSold from "components/administration/accounts/sold/AccountsSold";
import AccountsLocked from "components/administration/accounts/locked/AccountsLocked";
import AccountsLockedByReason from "components/administration/accounts/lockedByReason/AccountsLockedByReason";
import UsersCountAccounts from "components/administration/accounts/usersCount/UsersCountAccounts";
import ManualCalculate from "components/administration/accounts/manualCalculate/ManualCalculate";

export default Page;

function Page(props) {
  return (
    <>
      <Accounts />
      <div className="mb-3"></div>
      <AccountsSold />
      <div className="mb-3"></div>
      <AccountsLocked />
      <div className="mb-3"></div>
      <AccountsLockedByReason />
      <div className="mb-3"></div>
      <UsersCountAccounts />
      <div className="mb-3"></div>
      <ManualCalculate />
    </>
  );
}
