import React, { useCallback, useEffect, useRef } from "react";

const moment = require("moment");

export default AccountsTable;

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

function AccountsTable({ results, block }) {
  const table = useRef();

  const loadDataTable = useCallback(() => {
    if (!$.fn.DataTable.isDataTable(table.current)) {
      $(table.current).DataTable({
        data: results,
        dom: '<"top"Bf><"clear">lrtp<"bottom"i>',
        language: {
          emptyTable: "Нет данных",
          info: "Показано _START_ до _END_ из _TOTAL_",
          infoEmpty: "Показано 0 до 0 из 0",
          infoFiltered: "(Выбрано из _MAX_)",
          zeroRecords: "Не найдено",
          loadingRecords: "Загрузка...",
          search: "Поиск:",
        },
        responsive: true,
        paging: false,
        scrollY: 300,
        scrollX: true,
        order: [[1, "asc"]],
        columns: [
          {
            title: "",
            data: null,
            orderable: false,
            width: 10,
            render: function () {
              return "";
            },
          },
          {
            title: "Дата создания",
            data: "dateCreate",
            width: 100,
            render: function (t) {
              return moment(t).format("DD.MM.YYYY HH:mm");
            },
          },
          {
            title: "Аккаунт",
            data: "data",
          },
          {
            title: "Куплен",
            data: null,
            width: 20,
            render: function (c, s, d) {
              return d.status === 2 ? "Да" : "Нет";
            },
          },
          {
            title: "Когда",
            data: null,
            width: 100,
            render: function (c, s, d) {
              return d.status === 2
                ? moment(d.statusDate).format("DD.MM.YYYY HH:mm")
                : "-";
            },
          },
          {
            title: "Кем",
            data: null,
            render: function (c, s, d) {
              return d.status === 2 ? d.userData : "-";
            },
          },
          {
            title: "Блокировка",
            data: null,
            width: 10,
            className: "dt-center",
            render: function (t) {
              if (!t.locked) {
                return (
                  '<span class="block badge" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-unlock" viewBox="0 0 16 16">' +
                  '<path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2M3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"/>' +
                  "</svg></span>"
                );
              } else {
                return (
                  '<span class="block badge" style="cursor: pointer; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-lock" viewBox="0 0 16 16">' +
                  '<path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>' +
                  "</svg></span>"
                );
              }
            },
          },
        ],
      });
    } else {
      $(table.current).DataTable().clear();
      if (results) {
        $(table.current).DataTable().rows.add(results).draw(true);
      }
    }
  }, [results]);

  useEffect(() => {
    loadDataTable();
  }, [loadDataTable]);

  useEffect(() => {
    $(table.current)
      .off()
      .on("click", "span.block", function () {
        let choose = $(table.current)
          .DataTable()
          .row($(this).parent().parent())
          .data();

        block(choose);
      });

    setTimeout(() => {
      $(table.current).DataTable().columns.adjust().draw();
    }, 400);
  });

  return (
    <>
      <table className="w-100 display nowrap" ref={table}></table>
    </>
  );
}
