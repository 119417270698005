import React, { useEffect, useState } from "react";

import Widget from "components/shared/templates/Widget";
import Modal from "components/shared/Modal";

import ChecksTable from "./ChecksTable";
import ChecksStatistic from "./ChecksStatistic";
import ExpensesTable from "./ExpensesTable";
import SubTotalTable from "./SubTotalTable";

import { fetchWrapper } from "../../../../helpers/fetchwrapper";
import Calculator from "./Calculator/Calculator";

const moment = require("moment");
const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable.rowReorder = require("datatables.net-rowreorder");
$.DataTable.responsive = require("datatables.net-responsive");
$.DataTable.buttons = require("datatables.net-buttons");

export const AddChecksStatistic = () => {
  return {
    title: "Новый чек",
    body: (
      <>
        <div className="form-group">
          <label htmlFor="nominal">Сумма</label>
          <input
            type="number"
            className="form-control"
            id="nominal"
            placeholder="Введите сумму"
          />
        </div>
        <div className="form-group">
          <label htmlFor="count">Кол-во</label>
          <input
            type="number"
            className="form-control"
            id="count"
            placeholder="Введите кол-во"
          />
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        nominal: body.find("#nominal").val(),
        count: body.find("#count").val(),
      };

      return fetchWrapper.post("api/checks/addstatistic", data);
    },
  };
};

export const AddExpense = () => {
  return {
    title: "Новый расход",
    body: (
      <>
        <div className="form-group">
          <label htmlFor="name">Название</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Введите название"
          />
        </div>
        <div className="form-group">
          <label htmlFor="formula">Формула</label>
          <input
            type="text"
            className="form-control mb-2"
            id="formula"
            disabled={true}
          />
          <Calculator
            onChange={(data) => {
              $("#formula").val(data.formula);
              $("#sum").val(data.result);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="sum">Результат</label>
          <input
            type="text"
            className="form-control"
            id="sum"
            disabled={true}
          />
        </div>
      </>
    ),
    saveName: "Добавить",
    saveFunc: function (body) {
      let data = {
        name: body.find("#name").val(),
        formula: body.find("#formula").val(),
        sum: body.find("#sum").val(),
      };

      return fetchWrapper.post("api/expenses/add", data);
    },
  };
};

export default Totals;

function Totals(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [constructorDialog, setConstructorDialog] = useState({});
  const [loadForming, setLoadForming] = useState(false);
  const [results, setResults] = useState(null);
  const [toDay, setToDay] = useState(false);
  const [fromDate, setFromDate] = useState(moment().add(-1, "days").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().add(-1, "days").format("YYYY-MM-DD"));

  useEffect(() => {
    if (
      fromDate === moment().add(-1, "days").format("YYYY-MM-DD") &&
      toDate === moment().add(-1, "days").format("YYYY-MM-DD")
    ) {
      setToDay(true);
    } else {
      setToDay(false);
    }
  }, [fromDate, toDate]);

  const forming = () => {
    setResults(null);
    setLoadForming(true);
    let data = {
      from: fromDate,
      to: toDate,
    };
    fetchWrapper.post(`api/statistic/totalSum`, data).then((result) => {
      let sumChecks = result.checks.reduce((a, b) => a + b.sum, 0);
      result.checks.push({
        nominal: "Итог",
        percent: null,
        count: null,
        bonusCount: null,
        sum: sumChecks,
      });
      let sumChecksStatistic = result.checkTotalsStatistic.reduce(
        (a, b) => a + b.sum,
        0
      );
      result.checkTotalsStatistic.push({
        nominal: "Итог",
        percent: null,
        count: null,
        sum: sumChecksStatistic,
      });
      let sumExpenses = result.expenses.reduce((a, b) => a + b.sum, 0);
      result.expenses.push({
        name: "Итог",
        formula: null,
        sum: sumExpenses,
      });

      setResults(result);
      setLoadForming(false);
    });
  };

  return (
    <div className="mb-2">
      <Widget>
        <div className="row justify-content-between">
          <div className="d-sm-flex align-items-center col-sm-6">
            <div className="d-flex align-items-center">
              <h4>Итоги</h4>
            </div>
            <div className="d-flex align-items-center">
              <input
                disabled={loadForming}
                id="fromDate"
                type="date"
                className="ms-auto ms-sm-4 mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().add(-1, "days").format("YYYY-MM-DD")}
                onChange={(e) =>
                  setFromDate(
                    moment(e.currentTarget.value).format("YYYY-MM-DD")
                  )
                }
              ></input>
              <input
                disabled={loadForming}
                id="toDate"
                type="date"
                className="ms-2 me-auto mt-2 mb-2 form-control"
                style={{ maxWidth: 150 }}
                defaultValue={moment().add(-1, "days").format("YYYY-MM-DD")}
                onChange={(e) =>
                  setToDate(moment(e.currentTarget.value).format("YYYY-MM-DD"))
                }
              ></input>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end col-sm-6">
            <button
              disabled={loadForming}
              className="btn btn-primary ms-2"
              onClick={forming}
            >
              {loadForming && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Сформировать
            </button>
          </div>
        </div>
        <div className="row p-1">
          <div className="col-12 col-md-6">
            <h5>Чеки</h5>
            <ChecksTable data={results && results.checks} />
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex justify-content-between align-items-center">
              <h5>Чеки Юля</h5>
              {toDay ? (
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => {
                    setConstructorDialog(AddChecksStatistic());
                    setShowDialog(true);
                  }}
                >
                  Добавить
                </button>
              ) : null}
            </div>
            <ChecksStatistic data={results && results.checkTotalsStatistic} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-md-7">
            <div className="d-flex justify-content-between align-items-center">
              <h5>Расходы</h5>
              {toDay ? (
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => {
                    setConstructorDialog(AddExpense());
                    setShowDialog(true);
                  }}
                >
                  Добавить
                </button>
              ) : null}
            </div>
            <ExpensesTable data={results && results.expenses} />
          </div>
          <div className="col-12 col-md-5">
            <h5>Итог</h5>
            <SubTotalTable data={results && results.subTotals} />
          </div>
        </div>
      </Widget>
      {showDialog ? (
        <Modal
          hide={() => setShowDialog(false)}
          title={constructorDialog.title}
          body={constructorDialog.body}
          getDataFunc={constructorDialog.getDataFunc}
          saveName={constructorDialog.saveName}
          saveFunc={constructorDialog.saveFunc}
          successFunc={(response) => {
            setShowDialog(false);
            forming();
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
}
