import React, { useCallback, useEffect, useState } from "react";

import Widget from "components/shared/templates/Widget";
import { fetchWrapper } from "../../../helpers/fetchwrapper";

export default SettingsStatisticTotal;

function SettingsStatisticTotal(props) {
  const [loadSettings, setLoadSettings] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [separateChecks, setSeparateChecks] = useState(null);
  const [usePersentThirdCheck, setUsePersentThirdCheck] = useState(null);

  const getSettings = useCallback(() => {
    setLoadSettings(true);
    fetchWrapper
      .get(`api/statistic/getUserTotalSalarySettings`)
      .then((result) => {
        setSeparateChecks(result.separateChecks);
        setUsePersentThirdCheck(result.usePersentThirdCheck);
        setLoadSettings(false);
      });
  }, []);

  const saveSettings = () => {
    setLoadSave(true);
    fetchWrapper
      .post(`api/statistic/setUserTotalSalarySettings`, {
        separateChecks: separateChecks,
        usePersentThirdCheck: usePersentThirdCheck,
      })
      .then((result) => {
        setLoadSave(false);
      });
  };

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <>
      <Widget>
        <h4>Настройка общей статистики</h4>
        {loadSettings ? (
          <>
            <span className="spinner-border spinner-border-sm mr-1"></span>
          </>
        ) : (
          <>
            <div className="row">
              <div className="form-group col-12 col-md-4">
                <label htmlFor="separateChecks">Граница списков, тг</label>
                <input
                  type="number"
                  className="form-control"
                  id="separateChecks"
                  placeholder="Введите сумму границы"
                  defaultValue={separateChecks}
                  onChange={(e) => setSeparateChecks(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12 col-md-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={usePersentThirdCheck}
                  id="usePersentThirdCheck"
                  onChange={(e) => {
                    setUsePersentThirdCheck(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="usePersentThirdCheck"
                >
                  Процент для третьего чека
                </label>
              </div>
            </div>
            <button
              disabled={loadSave}
              className="btn btn-success mt-2"
              onClick={saveSettings}
            >
              {loadSave && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Сохранить
            </button>
          </>
        )}
      </Widget>
    </>
  );
}
